import { confirmDialog } from "primereact/confirmdialog";
import { createDistrict, deleteDistrict, updateDistrict, getAllDistricts } from "../../services/ValitanApiService";
import { enqueueSnackbar } from "notistack";

// Fetch Districts
export const getDistricts = async (page = 0, size = 10) => {
  try {
    const response = await getAllDistricts(page, size);
    if (response.data.status === "success") {
      const field = response.data.data;
      // console.log("Districts fetched:", response);
      return {
        districts: field.content,
        paginationInfo: {
          totalElements: field.totalElements,
          totalPages: field.totalPages,
          pageNumber: field.number,
          pageSize: field.size,
          sort: field.sort,
        },
      };
    } else {
      enqueueSnackbar(response.data.message || "Failed to fetch districts.", { variant: "error" });
      return { districts: [], paginationInfo: {} };
    }
  } catch (error) {
    console.error("Error fetching districts:", error);
    enqueueSnackbar("Error fetching districts. Please try again.", { variant: "error" });
    return { districts: [], paginationInfo: {} };
  }
};


// Delete district
export const deleteADistrict = async (id, loadDistricts) => {
  try {
    const response = await deleteDistrict(id);
    if (response.data.status === "success") {
      enqueueSnackbar(response.data.message, { variant: "success" });
      await loadDistricts();
    } else {
      enqueueSnackbar(response.data.message || "Failed to delete district.", { variant: "error" });
    }
  } catch (error) {
    enqueueSnackbar("Failed to delete district. Please try again.", { variant: "error" });
    console.error(error);
  }
};

export const confirmDelete = (id, loadDistricts) => {
  confirmDialog({
    message: "Are you sure you want to delete this district?",
    header: "Confirmation",
    icon: "pi pi-question-circle",
    accept: () => deleteADistrict(id, loadDistricts),
    reject: () => { },
  });
};

// Validation on create or update
export const validateDistrictForm = (districtName, selectedRegion, setErrors) => {
  let valid = true;
  const errorsCopy = { districtName: "", region: "" };

  if (districtName.trim()) {
    errorsCopy.districtName = "";
  } else {
    errorsCopy.districtName = "District Name is required";
    valid = false;
  }

  if (selectedRegion && selectedRegion.value) {
    errorsCopy.region = "";
  } else {
    errorsCopy.region = "Region is required";
    valid = false;
  }

  setErrors(errorsCopy);
  return valid;
};


// Create or update district
export const createOrUpdateDistrict = async (
  event,
  districtName,
  selectedRegion,
  editingDistrictId,
  loadDistricts,
  toggleModal,
  setErrors
) => {
  event.preventDefault();

  if (!validateDistrictForm(districtName, selectedRegion, setErrors)) {
    return;
  }

  // Prepare payload with correct structure
  const districtData = { 
    name: districtName, 
    region: { id: selectedRegion.value } // Include selectedRegion
  };

  try {
    const response = editingDistrictId
      ? await updateDistrict(editingDistrictId, districtData)
      : await createDistrict(districtData);

    if (response.data.status === "success") {
      enqueueSnackbar(response.data.message, { variant: "success" });
      toggleModal();
      await loadDistricts();
    } else {
      enqueueSnackbar(response.data.message || "Failed to save district data.", { variant: "error" });
    }
  } catch (error) {
    console.error("Error saving district data:", error);
    enqueueSnackbar("Error saving district data. Please try again.", { variant: "error" });
  }
};

