import React from "react";
import Routes from "./Routes";
import "react-toastify/dist/ReactToastify.css";

// Import Scss
import './assets/scss/theme.scss';
import { SnackbarProvider } from "notistack";


function App() {
  return (
    <React.Fragment>
      <SnackbarProvider
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Routes />
      </SnackbarProvider>
    </React.Fragment>
  );
}

export default App;
