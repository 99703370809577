import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";

// users
import user1 from "../../../assets/images/users/avatar-4.jpg";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState("Systme User");

  useEffect(() => {
    // Retrieve user data from local storage
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      try {
        const { user } = JSON.parse(authUser);
        setUsername(`${user.first_name} ${user.last_name}`);
      } catch (error) {
        console.error("Error parsing authUser from localStorage:", error);
        setUsername("System User"); // Fallback name
      }
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-2">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/userprofile">
            <i className="ri-user-line align-middle me-2" />
            {props.t("Profile")}
          </DropdownItem>

          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="ri-lock-unlock-line align-middle me-2" />
            {props.t("Lock screen")}
          </DropdownItem> */}

          <Link to="/change-password" className="dropdown-item">
            <i className="ri-lock-password-line align-middle me-2" />
            <span>{props.t("Change password")}</span>
          </Link>

          <div className="dropdown-divider" />

          <Link to="/logout" className="dropdown-item">
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
