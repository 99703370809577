import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ paginationInfo, handlePreviousPage, handleNextPage, onPageChange }) => {
    const startIndex = paginationInfo.pageNumber * paginationInfo.pageSize + 1;
    const endIndex = Math.min(startIndex + paginationInfo.pageSize - 1, paginationInfo.totalElements);

    return (
        <div className="d-flex justify-content-between align-items-center">
            {/* Left-aligned pagination summary */}
            <div className="pagination-summary">
                <span>Showing {startIndex} to {endIndex} of {paginationInfo.totalElements} entries</span>
            </div>

            {/* Right-aligned pagination controls */}
            <div className="d-inline-block">
                <ul className="pagination pagination-rounded mb-0">
                    {/* Previous Button */}
                    <li className={`page-item ${paginationInfo.pageNumber === 0 ? 'disabled' : ''}`}>
                        <Link
                            className="page-link"
                            aria-label="Previous"
                            to="#"
                            onClick={handlePreviousPage}
                            style={{
                                pointerEvents: paginationInfo.pageNumber === 0 ? 'none' : 'auto',
                                opacity: paginationInfo.pageNumber === 0 ? 0.5 : 1
                            }}
                        >
                            <i className="mdi mdi-chevron-left"></i>
                        </Link>
                    </li>

                    {/* Page Numbers */}
                    {[...Array(paginationInfo.totalPages)].map((_, index) => (
                        <li className={`page-item ${paginationInfo.pageNumber === index ? 'active' : ''} ms-2`} key={index}>
                            <Link
                                className="page-link"
                                to="#"
                                onClick={() => onPageChange(index, paginationInfo.pageSize)}
                            >
                                {index + 1}
                            </Link>
                        </li>
                    ))}

                    {/* Next Button */}
                    <li className={`page-item ${paginationInfo.pageNumber === paginationInfo.totalPages - 1 ? 'disabled' : ''}`}>
                        <Link
                            className="page-link"
                            aria-label="Next"
                            to="#"
                            onClick={handleNextPage}
                            style={{
                                pointerEvents: paginationInfo.pageNumber === paginationInfo.totalPages - 1 ? 'none' : 'auto',
                                opacity: paginationInfo.pageNumber === paginationInfo.totalPages - 1 ? 0.5 : 1
                            }}
                        >
                            <i className="mdi mdi-chevron-right"></i>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Pagination;
