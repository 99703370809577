import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { enqueueSnackbar } from "notistack";

const apiUrl = 'https://valitantest-api.webappstechnologies.com/api';

// saga.js
function* loginUser({ payload: { user, navigate } }) {
  try {
    const response = yield call(
      axios.post,
      `${apiUrl}/auth/login`,
      {
        email: user.email,
        password: user.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Console log the entire response
    // console.log("Login response:", response);

    const { accessToken, tokenType, user: userDetails } = response;

    // Console log the accessToken, tokenType, and userDetails
    // console.log("Access Token:", accessToken);
    // console.log("Token Type:", tokenType);
    // console.log("User Details:", userDetails);

    // Store token and user details in localStorage
    localStorage.setItem("authUser", JSON.stringify({
      token: accessToken,
      tokenType: tokenType,
      user: userDetails,
    }));

    // Dispatch login success action with user data
    yield put(loginSuccess(response.data));
    enqueueSnackbar('Logged in successfully!', { variant: 'success' });

    // Navigate to dashboard
    navigate("/dashboard");
  } catch (error) {
    console.error("Login error details:", error);

    const errorMessage = error.response?.data.message || "Login failed";
    yield put(apiError(errorMessage));
  }
}

function* logoutUser({ payload: { navigate } }) {
  try {
    // Remove token and user details from localStorage
    localStorage.removeItem("authUser");

    // Dispatch logout success action
    yield put(logoutUserSuccess());

    // Navigate to login page
    navigate("/login");
  } catch (error) {
    console.error("Logout error details:", error);
  }
}

// Watcher saga
function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
