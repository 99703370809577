import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import logoSample from "../../assets/images/logo_sample.png";
import Pagination from "../../components/Pagination/Pagination";
import formatDate from "../Utility/DateFormat";
import {
  confirmDelete,
  createOrUpdateCompany,
  getCompanies as fetchCompanies,
  viewCompanyDetails,
} from "../Companies/CompanyService";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { getCompany } from "../../services/ValitanApiService";
import { ConfirmDialog } from "primereact/confirmdialog";

const CompanyListTables = () => {
  document.title = "Companies | Valitan";

  const [companies, setCompanies] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyTin, setCompanyTin] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyMobileNumber, setCompanyMobileNumber] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyCreatedAt, setCompanyCreatedAt] = useState("");
  const [logoPreview, setLogoPreview] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    companyName: "",
    companyTin: "",
    companyMobileNumber: "",
  });
  const [editingCompanyId, setEditingCompanyId] = useState(null);
  const [paginationInfo, setPaginationInfo] = useState({
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 10,
    sort: {},
  });

  useEffect(() => {
    loadCompanies(0, paginationInfo.pageSize);
  }, [paginationInfo.pageSize]);

  const loadCompanies = async (page = 0, size = 10) => {
    const { companies, paginationInfo } = await fetchCompanies(page, size);
    setCompanies(companies);
    setPaginationInfo(paginationInfo);
  };

  const handleNextPage = () => {
    if (paginationInfo.pageNumber < paginationInfo.totalPages - 1) {
      loadCompanies(paginationInfo.pageNumber + 1, paginationInfo.pageSize);
    }
  };

  const handlePreviousPage = () => {
    if (paginationInfo.pageNumber > 0) {
      loadCompanies(paginationInfo.pageNumber - 1, paginationInfo.pageSize);
    }
  };

  const openModalForDetails = async (id) => {
    try {
      await viewCompanyDetails(
        id,
        setLoadingDetails,
        setCompanyName,
        setCompanyTin,
        setCompanyAddress,
        setCompanyMobileNumber,
        setCompanyEmail,
        setCompanyLogo,
        setCompanyCreatedAt,
        setDetailsModalOpen
      );
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  const openModalForEdit = async (id) => {
    setEditingCompanyId(id);
    try {
      const response = await getCompany(id);
      if (response) {
        const  field = response.data.data;
        setCompanyName(field.name);
        setCompanyTin(field.tin);
        setCompanyAddress(field.address);
        setCompanyMobileNumber(field.mobileNumber);
        setCompanyEmail(field.email);
        setCompanyLogo(field.logo);
        setCompanyCreatedAt(field.createdAt);
        setModalOpen(true);
      } else {
        console.error("Company data is undefined");
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setCompanyName("");
      setCompanyTin("");
      setCompanyAddress("");
      setCompanyMobileNumber("");
      setCompanyEmail("");
      setCompanyLogo("");

      setErrors({
        companyName: "",
        companyTin: "",
        companyMobileNumber: "",
      });
      setEditingCompanyId();
    }
  };

  const startIndex = paginationInfo.pageNumber * paginationInfo.pageSize + 1;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCompanyLogo(file);

    // Generate a preview URL for the logo
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setLogoPreview(null);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Companies management"
            breadcrumbItem="Companies"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    <Button
                      color="primary"
                      className="add-btn float-end"
                      id="TooltipTop"
                      onClick={toggleModal}
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add
                    </Button>
                  </h4>
                </CardHeader>

                <CardBody>
                  <div>
                    <Row className="g-4 mb-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="email">
                              #
                            </th>
                            <th className="sort" data-sort="email">
                              TIN
                            </th>
                            <th className="sort" data-sort="email">
                              Name
                            </th>
                            <th className="sort" data-sort="phone">
                              Mbile Number
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {companies.length > 0 ? (
                            companies.map((company, index) => (
                              <tr key={company.id}>
                                <td>{startIndex + index}</td>
                                <td>{company.tin}</td>
                                <td>{company.name}</td>
                                <td>{company.mobileNumber}</td>
                                <td>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic example"
                                  >
                                    <button
                                      type="button"
                                      onClick={() =>
                                        openModalForDetails(
                                          company.id,
                                          // setLoading,
                                          setCompanyName,
                                          setCompanyTin,
                                          setCompanyAddress,
                                          setCompanyMobileNumber,
                                          setCompanyEmail,
                                          setCompanyLogo,
                                          setCompanyCreatedAt,
                                          setDetailsModalOpen
                                        )
                                      }
                                      className="btn btn-primary btn-sm"
                                    >
                                      <i className="ri-eye-line"></i>
                                    </button>
                                    <button
                                      id="TooltipTop"
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        openModalForEdit(company.id)
                                      }
                                    >
                                      <i className="ri-edit-box-line"></i>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        confirmDelete(company.id, loadCompanies)
                                      }
                                      className="btn btn-danger btn-sm"
                                    >
                                      <i className="ri-delete-bin-line"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>
                                No records found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      paginationInfo={paginationInfo}
                      handleNextPage={handleNextPage}
                      handlePreviousPage={handlePreviousPage}
                      onPageChange={(pageNumber, pageSize) => loadCompanies(pageNumber, pageSize)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ConfirmDialog */}
      <ConfirmDialog />

      <Modal isOpen={modalOpen} toggle={toggleModal} backdrop={"static"}>
        <ModalHeader toggle={toggleModal}>
          {editingCompanyId ? "Edit Company" : "Add Company"}
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={(evt) =>
              createOrUpdateCompany(
                evt,
                companyName,
                companyTin,
                companyMobileNumber,
                companyAddress,
                companyEmail,
                companyLogo,
                editingCompanyId,
                loadCompanies,
                toggleModal,
                setErrors
              )
            }
          >
            <FormGroup>
              <Label for="companyTin">TIN</Label>
              <Input
                type="text"
                name="companyTin"
                id="companyTin"
                placeholder="Enter TIN"
                value={companyTin}
                onChange={(e) => setCompanyTin(e.target.value)}
                invalid={!!errors.companyTin}
              />
              {errors.companyTin && (
                <div className="invalid-feedback">{errors.companyTin}</div>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="companyName">Name</Label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Enter Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                invalid={!!errors.companyName}
              />
              {errors.companyName && (
                <div className="invalid-feedback">{errors.companyName}</div>
              )}
            </FormGroup>
            <Row>
              <FormGroup className="col-6">
                <Label for="companyMobileNumber">Mobile Number</Label>
                <Input
                  type="text"
                  name="companyMobileNumber"
                  id="companyMobileNumber"
                  placeholder="Enter Mobile Number"
                  value={companyMobileNumber}
                  onChange={(e) => setCompanyMobileNumber(e.target.value)}
                  invalid={!!errors.companyMobileNumber}
                />
                {errors.companyMobileNumber && (
                  <div className="invalid-feedback">
                    {errors.companyMobileNumber}
                  </div>
                )}
              </FormGroup>
              <FormGroup className="col-6">
                <Label for="companyEmail">Email</Label>
                <Input
                  type="text"
                  name="companyEmail"
                  id="companyEmail"
                  placeholder="Enter Email"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                />
              </FormGroup>
            </Row>

            <FormGroup>
              <Label for="companyAddress">Address</Label>
              <Input
                type="text"
                name="companyAddress"
                id="companyAddress"
                placeholder="Enter Address"
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
              />
            </FormGroup>
            <Row>
              <FormGroup className="col-7">
                <Label for="companyLogo">Logo</Label>
                <Input
                  type="file"
                  name="companyLogo"
                  id="companyLogo"
                  onChange={handleFileChange}
                />
              </FormGroup>
              {logoPreview && (
                <div className="col-4 float-end">
                  <img
                    src={logoPreview}
                    className="avatar-md rounded-circle img-thumbnail"
                    alt="Logo Preview"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              )}
            </Row>
            <Button type="submit" color="primary" className="float-end">
              {editingCompanyId ? "Update" : "Save"}
            </Button>
          </form>
        </ModalBody>
      </Modal>

      <Modal
  isOpen={detailsModalOpen}
  toggle={() => setDetailsModalOpen(false)}
  backdrop={"static"}
  className="modal-lg" // You can adjust this to modal-xl or use custom width as needed
>
  <ModalHeader toggle={() => setDetailsModalOpen(false)}>
    <span style={{ marginRight: "2em" }}>
      <strong>Details</strong>
    </span>
    {companyTin}
  </ModalHeader>
  <ModalBody>
    {loadingDetails ? (
      <div>Loading...</div>
    ) : (
      <Row className="d-flex">
        <div className="col-md-8">
          <div className="plan-features mt-2 mb-3">
            <p className="d-flex align-items-center">
              <i className="mdi mdi-checkbox-marked-circle-outline font-size-18 align-middle text-primary me-2"></i>{" "}
              <span style={{ marginRight: "2em" }}>
                <strong>Name:</strong>
              </span>
              {companyName}
            </p>
            <p>
              <i className="mdi mdi-checkbox-marked-circle-outline font-size-16 align-middle text-primary me-2"></i>{" "}
              <span style={{ marginRight: "2em" }}>
                <strong>Mobile Number:</strong>
              </span>
              {companyMobileNumber}
            </p>
            <p>
              <i className="mdi mdi-checkbox-marked-circle-outline font-size-16 align-middle text-primary me-2"></i>{" "}
              <span style={{ marginRight: "2em" }}>
                <strong>Email:</strong>
              </span>
              {companyEmail}
            </p>
            <p>
              <i className="mdi mdi-checkbox-marked-circle-outline font-size-16 align-middle text-primary me-2"></i>{" "}
              <span style={{ marginRight: "2em" }}>
                <strong>Address:</strong>
              </span>
              {companyAddress}
            </p>
          </div>
        </div>

        <div className="col-3 ms-auto">
                  <img
                    src={logoSample}
                    className="avatar-md img-thumbnail"
                    alt="Logo Preview"
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>
      </Row>
    )}
  </ModalBody>
  <ModalFooter>
    <p>
      <i className="mdi mdi-calendar font-size-14 align-middle text-primary"></i>{" "}
      <span style={{ marginRight: "2em" }}>
        <small>Created at:</small>
      </span>
      <small>{formatDate(companyCreatedAt)}</small>
    </p>
  </ModalFooter>
</Modal>

    </React.Fragment>
  );
};

export default CompanyListTables;
