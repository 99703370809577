import React from 'react';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from 'react-router-dom';

const AssetListTables = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Assets valuation" breadcrumbItem="Asset list"/>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                {/*<CardHeader>*/}
                                {/*    <h4 className="card-title mb-0">*/}
                                {/*        <Button color="primary" className="add-btn float-end"*/}
                                {/*                id="create-btn"><i*/}
                                {/*            className="ri-add-line align-bottom me-1"></i> Add</Button>*/}
                                {/*    </h4>*/}
                                {/*</CardHeader>*/}

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search"
                                                               placeholder="Search..."/>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                <tr>
                                                    <th className="sort" data-sort="customer_name">Customer</th>
                                                    <th className="sort" data-sort="email">Email</th>
                                                    <th className="sort" data-sort="phone">Phone</th>
                                                    <th className="sort" data-sort="date">Joining Date</th>
                                                    <th className="sort" data-sort="status">Delivery Status</th>
                                                    <th className="sort" data-sort="action">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="customer_name">Mary Cousar</td>
                                                    <td className="email">marycousar@velzon.com</td>
                                                    <td className="phone">580-464-4694</td>
                                                    <td className="date">06 Apr, 2021</td>
                                                    <td className="status"><span
                                                        className="badge badge-soft-success text-uppercase">Active</span>
                                                    </td>
                                                    <td>
                                                        <div class="btn-group" role="group"
                                                             aria-label="Basic example">
                                                            <button type="button"
                                                                    className="btn btn-primary btn-sm">
                                                                <i className="ri-eye-line"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-primary btn-sm">
                                                                <i className="ri-edit-box-line"></i>
                                                            </button>
                                                            <button type="button"
                                                                    className="btn btn-danger btn-sm">
                                                                <i className="ri-delete-bin-line"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="id" style={{display: "none"}}><Link to="#"
                                                                                                       className="fw-medium link-primary">#VZ2102</Link>
                                                    </td>
                                                    <td className="customer_name">Jeff Taylor</td>
                                                    <td className="email">jefftaylor@velzon.com</td>
                                                    <td className="phone">863-577-5537</td>
                                                    <td className="date">15 Feb, 2021</td>
                                                    <td className="status"><span
                                                        className="badge badge-soft-success text-uppercase">Active</span>
                                                    </td>
                                                    <td>
                                                        <div class="btn-group" role="group"
                                                             aria-label="Basic example">
                                                            <button type="button"
                                                                    className="btn btn-primary btn-sm">
                                                                <i className="ri-eye-line"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-primary btn-sm">
                                                                <i className="ri-edit-box-line"></i>
                                                            </button>
                                                            <button type="button"
                                                                    className="btn btn-danger btn-sm">
                                                                <i className="ri-delete-bin-line"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    );
};

export default AssetListTables;
