import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { createUser, updateUser, getUser } from '../../services/ValitanApiService';
import { getRoles as fetchRoles } from '../../Pages/Security/Roles/RoleService';
import FormField from '../../components/Common/FormFields';
import { enqueueSnackbar } from 'notistack';

const UsersListTables = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        mobile_number: '',
        email: '',
        address: '',
        role_id: '', // Use only role_id here
        sex: '',
    });

    const [errors, setErrors] = useState({});
    const [roles, setRoles] = useState([]);
    const [rolesLoaded, setRolesLoaded] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // New state for edit mode
    const navigate = useNavigate();
    const { userId } = useParams(); // Get userId from route params

    useEffect(() => {
        const fetchRolesData = async () => {
            if (!rolesLoaded) {
                try {
                    const { roles } = await fetchRoles(); // Fetch roles
                    setRoles(roles);
                    setRolesLoaded(true); // Mark roles as loaded
                } catch (error) {
                    enqueueSnackbar('Error fetching roles', { variant: 'error' }); // Use Snackbar
                }
            }
        };

        fetchRolesData();
    }, [rolesLoaded]);

    useEffect(() => {
        if (userId) {
            // Fetch user data for editing
            const fetchUser = async () => {
                try {
                    const user = await getUser(userId);
                    setFormData({
                        first_name: user.data.first_name || '',
                        middle_name: user.data.middle_name || '',
                        last_name: user.data.last_name || '',
                        mobile_number: user.data.mobile_number || '',
                        email: user.data.email || '',
                        address: user.data.address || '',
                        role_id: user.data.role.id || '', // Set role_id directly
                        sex: user.data.sex || '',
                    });
                    setIsEditing(true); // Set edit mode
                } catch (error) {
                    enqueueSnackbar('Error fetching user data!', { variant: 'error' }); // Use Snackbar
                }
            };
            fetchUser();
        }
    }, [userId]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRoleDropdownClick = async () => {
        if (!rolesLoaded) {
            try {
                const { roles } = await fetchRoles(); // Fetch roles
                setRoles(roles);
                setRolesLoaded(true); // Mark roles as loaded
            } catch (error) {
                enqueueSnackbar('Error fetching roles', { variant: 'error' }); // Use Snackbar
            }
        }
    };

    const capitalizeFieldName = (field) => {
        return field
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
    };

    const validateForm = () => {
        const requiredFields = ['first_name', 'middle_name', 'last_name', 'mobile_number', 'email', 'role_id', 'sex'];
        let newErrors = {};

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${capitalizeFieldName(field)} is required`;
            }
        });

        // Validate mobile number
        if (formData.mobile_number && !/^\d{10}$/.test(formData.mobile_number)) {
            newErrors.mobile_number = 'Allowed only 10 digits';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        if (validateForm()) {
            try {
                const payload = {
                    ...formData,
                    role_id: formData.role_id,
                };
    
                if (isEditing) {
                    // Update user
                    await updateUser(userId, payload);
                    enqueueSnackbar('User updated successfully', { variant: 'success' });
                } else {
                    // Create user
                    await createUser(payload);
                    enqueueSnackbar('User created successfully', { variant: 'success' });
                }
                navigate('/users');
            } catch (error) {
                enqueueSnackbar('Error processing user', { variant: 'error' });
            }
        } else {
            console.log('Validation errors:', errors);
        }
    };

    const handleKeyPress = (e) => {
        // Allow only numeric input
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const fields = [
        { id: 'first_name', label: 'First Name', placeholder: 'Enter Your First Name' },
        { id: 'middle_name', label: 'Middle Name', placeholder: 'Enter Your Middle Name' },
        { id: 'last_name', label: 'Last Name', placeholder: 'Enter Your Last Name' },
        { id: 'email', label: 'Email', placeholder: 'Enter Your Email Address', type: 'email', colSize: 6, disabled: isEditing }, // Disabled when editing
        { id: 'mobile_number', label: 'Mobile Number', placeholder: 'Enter Your Mobile Number', colSize: 6, inputMode: 'numeric', onKeyPress: handleKeyPress }
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Users management" breadcrumbItem={isEditing ? "Edit user" : "Add user"} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            {fields.map(({ id, label, placeholder, type = 'text', colSize = 4, disabled = false, inputMode, onKeyPress }) => (
                                                <Col lg={colSize} key={id}>
                                                    <FormField
                                                        id={id}
                                                        name={id}
                                                        label={label}
                                                        placeholder={placeholder}
                                                        type={type}
                                                        value={formData[id]}
                                                        onChange={handleChange}
                                                        error={errors[id]}
                                                        disabled={disabled}
                                                        inputMode={inputMode} // Set input mode for numeric fields
                                                        onKeyPress={onKeyPress} // Handle key press for validation
                                                    />
                                                </Col>
                                            ))}
                                        </Row>

                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label htmlFor="role_id">Role</Label>
                                                    <Input
                                                        id="role_id"
                                                        name="role_id"
                                                        type="select"
                                                        value={formData.role_id}
                                                        onChange={handleChange}
                                                        onClick={handleRoleDropdownClick} // Fetch roles when dropdown is clicked
                                                        invalid={!!errors.role_id}
                                                    >
                                                        <option value="">Select Role</option>
                                                        {roles.map(role => (
                                                            <option key={role.id} value={role.id}>
                                                                {role.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                    {errors.role_id && <div className="invalid-feedback">{errors.role_id}</div>}
                                                </FormGroup>
                                            </Col>

                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label htmlFor="sex">Sex</Label>
                                                    <Input
                                                        id="sex"
                                                        name="sex"
                                                        type="select"
                                                        value={formData.sex}
                                                        onChange={handleChange}
                                                        invalid={!!errors.sex}
                                                    >
                                                        <option value="">Select Sex</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </Input>
                                                    {errors.sex && <div className="invalid-feedback">{errors.sex}</div>}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {/* Address field */}
                                        <Row>
                                            <Col lg="12">
                                                <FormField
                                                    id="address"
                                                    name="address"
                                                    label="Address"
                                                    placeholder="Enter Your Address"
                                                    type="textarea"
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                    error={errors.address}
                                                />
                                            </Col>
                                        </Row>

                                        <Button className="float-end" color="primary" type="submit">
                                            {isEditing ? 'Update' : 'Submit'}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UsersListTables;
