import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from 'react-router-dom';
import { blockActivateUser, getAllUsers, resetUser } from '../../services/ValitanApiService';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import { enqueueSnackbar } from 'notistack';
import { ConfirmBlockDialog, ConfirmResetDialog } from './Common';
import Pagination from "../../components/Pagination/Pagination";
import { useSearch } from '../Users/UserSearch';

const UsersListTables = () => {
    document.title = "Users | Valitan";

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [paginationInfo, setPaginationInfo] = useState({
        pageNumber: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0
    });

    const fetchUsers = async (pageNumber = 0, pageSize = 10) => {
        try {
            setLoading(true);
            const response = await getAllUsers(pageNumber, pageSize);
            setUsers(response.data.content);
            setPaginationInfo({
                pageNumber: response.data.pageable.pageNumber,
                pageSize: response.data.pageable.pageSize,
                totalElements: response.data.totalElements,
                totalPages: response.data.totalPages
            });
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers(); // Default to the first page
    }, []);

    // Pass the fetched users into the useSearch hook
    const { searchQuery, filteredItems, handleSearchChange } = useSearch(users);

    const handlePreviousPage = () => {
        if (paginationInfo.pageNumber > 0) {
            fetchUsers(paginationInfo.pageNumber - 1, paginationInfo.pageSize);
        }
    };

    const handleNextPage = () => {
        if (paginationInfo.pageNumber < paginationInfo.totalPages - 1) {
            fetchUsers(paginationInfo.pageNumber + 1, paginationInfo.pageSize);
        }
    };

    const handleBlockUnblockUser = async (userId, actionType) => {
        try {
            const response = await blockActivateUser(userId);
            if (response.status === 200) {
                setUsers(prevUsers =>
                    prevUsers.map(user =>
                        user.id === userId ? { ...user, isBanned: actionType === 'block' ? 'Yes' : 'No' } : user
                    )
                );
                enqueueSnackbar(actionType === 'block' ? "User successfully banned" : "User successfully activated", { variant: "success" });
                await fetchUsers(paginationInfo.pageNumber, paginationInfo.pageSize);
            } else {
                enqueueSnackbar("Failed to update user status", { variant: "error" });
            }
        } catch (error) {
            console.error("Error blocking/unblocking user:", error);
            enqueueSnackbar("An error occurred while updating", { variant: "error" });
            setError(error);
        }
    };

    const handleUserResetPassword = async (userId) => {
        try {
            const response = await resetUser(userId);
            if (response.status === 200) {
                enqueueSnackbar("User password successfully reset", { variant: "success" });
            } else {
                enqueueSnackbar("Failed to reset user password", { variant: "error" });
            }
        } catch (error) {
            console.error("Error resetting user password:", error);
            enqueueSnackbar("An error occurred while resetting password", { variant: "error" });
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Users management" breadcrumbItem="Users" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">
                                        <Link to="/add-user">
                                            <Button color="primary" className="add-btn float-end" id="create-btn">
                                                <i className="ri-add-line align-bottom me-1"></i> Add
                                            </Button>
                                        </Link>
                                    </h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder="Search..."
                                                            value={searchQuery}
                                                            onChange={handleSearchChange}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        {loading ? (
                                            <div>Loading...</div>
                                        ) : error ? (
                                            <div>Error fetching users: {error.message}</div>
                                        ) : (
                                            <div className="table-responsive table-card mt-3 mb-1">
                                                <table className="table align-middle table-nowrap" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="sort" data-sort="first_name">#</th>
                                                            <th className="sort" data-sort="first_name">Name</th>
                                                            <th className="sort" data-sort="phone">Mobile Number</th>
                                                            <th className="sort" data-sort="email">Email</th>
                                                            <th className="sort" data-sort="email">Role</th>
                                                            <th className="sort" data-sort="email">Sex</th>
                                                            <th className="sort" data-sort="action">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredItems.length > 0 ? (
                                                            filteredItems.map((user, index) => (
                                                                <tr key={user.id}>
                                                                    <td className="first_name">{paginationInfo.pageNumber * paginationInfo.pageSize + index + 1}</td>
                                                                    <td className="first_name">{user.first_name} {user.last_name}</td>
                                                                    <td className="phone">{user.mobile_number}</td>
                                                                    <td className="email">{user.email}</td>
                                                                    <td className="email">{user.role.name}</td>
                                                                    <td className="email">{user.sex}</td>
                                                                    <td>
                                                                        <div className="btn-group" role="group" aria-label="Basic example">
                                                                            <Link to={`/user-info/${user.id}`} className="btn btn-primary btn-sm">
                                                                                <i className="ri-eye-line"></i>
                                                                            </Link>
                                                                            <Link to={`/update-user/${user.id}`} type="button" className="btn btn-primary btn-sm">
                                                                                <i className="ri-edit-box-line"></i>
                                                                            </Link>
                                                                            <ConfirmResetDialog
                                                                                userId={user.id}
                                                                                isBlocked={user.isBanned}
                                                                                onAccept={handleUserResetPassword}
                                                                            />
                                                                            <ConfirmBlockDialog
                                                                                userId={user.id}
                                                                                isBlocked={user.isBanned}
                                                                                onAccept={handleBlockUnblockUser}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="7" className="text-center">No records found to match your search query.</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}

                                        {/* Pagination component */}
                                        <Pagination
                                            paginationInfo={paginationInfo}
                                            handleNextPage={handleNextPage}
                                            handlePreviousPage={handlePreviousPage}
                                            onPageChange={(pageNumber, pageSize) => fetchUsers(pageNumber, pageSize)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmDialog />
            </div>
        </React.Fragment>
    );
};

export default UsersListTables;
