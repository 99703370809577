import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useParams } from 'react-router-dom';
import Breadcrumb from "../../components/Common/Breadcrumb";
import { getUser } from "../../services/ValitanApiService";
import avatar from "../../assets/images/users/avatar-4.jpg";

const UserDetails = () => {
    document.title = "User Details | Valitan";

    const { userId } = useParams();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchUser = async () => {
            try {
                setLoading(true);
                const response = await getUser(userId);
                
                if (response.status === 200) {
                    setUser(response.data);
                    setNotFound(false);
                } else {
                    setNotFound(true);
                    setErrorMessage('User not found!');
                }
            } catch (error) {
                setNotFound(true);
                setErrorMessage('User not found!');
            } finally {
                setLoading(false);
            }
        };
        fetchUser();
    }, [userId]);

    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        backdropFilter: 'blur(5px)'
    };

    const spinnerStyle = {
        width: '3rem',
        height: '3rem',
        borderWidth: '0.4em',
        borderTopColor: 'transparent',
        borderStyle: 'solid',
        borderRadius: '50%',
        borderColor: '#9aa0a6'
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="Users management" breadcrumbItem="Details" />
                    {loading && (
                        <div style={overlayStyle}>
                            <div style={spinnerStyle} className="spinner-border">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}
                    {loading ? null : notFound ? (
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="text-center">
                                            <h5 className="text-danger"><em>{errorMessage}</em></h5>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col lg="4">
                                <Card>
                                    <CardBody>
                                        <div>
                                            <img
                                                src={avatar}
                                                alt="User Avatar"
                                                className="avatar-lg rounded-circle img-thumbnail mx-auto d-block"
                                                style={{ marginBottom: '16px' }}
                                            />
                                        </div>
                                        <hr style={{
                                            width: '90%',
                                            margin: '20px auto',
                                            border: '0',
                                            height: '1px',
                                            backgroundImage: 'linear-gradient(to right, rgba(0,0,0,0), #999da1, rgba(0,0,0,0))'
                                        }} />
                                        <div className="text-muted">
                                        <table className="table table-md">
                                                <tbody>
                                                    <tr>
                                                        <td><i className="ri-share-forward-line font-size-16 align-middle text-primary me-2"></i> First Name:</td>
                                                        <td>{user.first_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className="ri-share-forward-line font-size-16 align-middle text-primary me-2"></i> Middle Name:</td>
                                                        <td>{user.middle_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className="ri-share-forward-line font-size-16 align-middle text-primary me-2"></i> Last Name:</td>
                                                        <td>{user.last_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className="ri-share-forward-line font-size-16 align-middle text-primary me-2"></i> Sex:</td>
                                                        <td>{user.sex}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="text-center text-white bg-primary text-uppercase">{user.role.name}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="8" className="ms-auto">
                                <Card>
                                    <CardBody>
                                        <div className="text-muted">
                                            <p><i className="ri-user-6-fill font-size-16 align-middle text-primary me-2"></i> First Name: {user.first_name}</p>
                                            {/* Add more user details here as needed */}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserDetails;
