import { useState, useEffect } from 'react';

// Custom hook for handling search functionality
export const useSearch = (initialItems) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredItems, setFilteredItems] = useState(initialItems);

    useEffect(() => {
        // When initialItems change, reset the filteredItems to the full list
        setFilteredItems(initialItems);
    }, [initialItems]);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        filterItems(query);
    };

    const filterItems = (query) => {
        const lowerQuery = query.toLowerCase();
        const result = initialItems.filter(item => {
            const fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
            return (
                fullName.includes(lowerQuery) ||
                item.email.toLowerCase().includes(lowerQuery) ||
                item.mobile_number.includes(lowerQuery)
            );
        });
        setFilteredItems(result);
    };

    return {
        searchQuery,
        filteredItems,
        handleSearchChange
    };
};
