import {
  API_ERROR,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  isUserLogout: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: "", // Clear error on new login attempt
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "", // Clear error on successful login
      };

    case LOGOUT_USER:
      return {
        ...state,
        isUserLogout: false,
        loading: true, // Optional: could represent a loading state during logout
      };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        isUserLogout: true,
        loading: false,
        error: "", // Clear error on successful logout
      };

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default login;
