import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import logoSample from "../../assets/images/logo_sample.png";
import Pagination from "../../components/Pagination/Pagination";
import formatDate from "../Utility/DateFormat";
import {
  confirmDelete,
  createOrUpdateClient,
  getClients as fetchClients,
  viewClientDetails,
} from "../Clients/ClientService";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { getClient } from "../../services/ValitanApiService";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useSearch } from "./SearchClient";

const ClientListTables = () => {
  document.title = "Clients | Valitan";

  const [clients, setClients] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [
    // loadingDetails, 
    setLoadingDetails] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientTin, setClientTin] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientMobileNumber, setClientMobileNumber] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientLogo, setClientLogo] = useState("");
  const [clientIsPrivate, setClientIsPrivate] = useState("");
  const [clientCreatedAt, setClientCreatedAt] = useState("");
  const [logoPreview, setLogoPreview] = useState(null);
  const [errors, setErrors] = useState({
    clientName: "",
    clientTin: "",
    clientMobileNumber: "",
    clientIsPrivate: "",
  });
  const [editingClientId, setEditingClientId] = useState(null);
  const [paginationInfo, setPaginationInfo] = useState({
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 10,
    sort: {},
  });

  // Use the custom hook for search functionality
  const { searchQuery, filteredItems, handleSearchChange } = useSearch(clients);

  useEffect(() => {
    loadClients(0, paginationInfo.pageSize);
  }, [paginationInfo.pageSize]);

  const loadClients = async (page = 0, size = 10) => {
    const { clients, paginationInfo } = await fetchClients(page, size);
    setClients(clients);
    setPaginationInfo(paginationInfo);
  };

  const handleNextPage = () => {
    if (paginationInfo.pageNumber < paginationInfo.totalPages - 1) {
      loadClients(paginationInfo.pageNumber + 1, paginationInfo.pageSize);
    }
  };

  const handlePreviousPage = () => {
    if (paginationInfo.pageNumber > 0) {
      loadClients(paginationInfo.pageNumber - 1, paginationInfo.pageSize);
    }
  };

  const openModalForDetails = async (id) => {
    try {
      await viewClientDetails(
        id,
        setLoadingDetails,
        setClientName,
        setClientTin,
        setClientAddress,
        setClientMobileNumber,
        setClientEmail,
        setClientLogo,
        setClientIsPrivate,
        setClientCreatedAt,
        setDetailsModalOpen
      );
    } catch (error) {
      console.error("Error fetching client details:", error);
    }
  };

  const openModalForEdit = async (id) => {
    setEditingClientId(id);
    try {
      const response = await getClient(id);
      if (response) {
        const field = response.data.data;
        setClientName(field.name);
        setClientTin(field.tin);
        setClientAddress(field.address);
        setClientMobileNumber(field.mobile_number);
        setClientEmail(field.email);
        setClientLogo(field.logo);
        setClientIsPrivate(field.is_private);
        setClientCreatedAt(field.created_at);
        setModalOpen(true);
      } else {
        console.error("Client data is undefined");
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setClientName("");
      setClientTin("");
      setClientAddress("");
      setClientMobileNumber("");
      setClientEmail("");
      setClientLogo("");
      setClientIsPrivate("");

      setErrors({
        clientName: "",
        clientTin: "",
        clientMobileNumber: "",
        clientIsPrivate: "",
      });
      setEditingClientId();
    }
  };

  const startIndex = paginationInfo.pageNumber * paginationInfo.pageSize + 1;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setClientLogo(file);

    // Generate a preview URL for the logo
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setLogoPreview(null);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Clients management"
            breadcrumbItem="Clients"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    <Button
                      color="primary"
                      className="add-btn float-end"
                      id="TooltipTop"
                      onClick={toggleModal}
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add
                    </Button>
                  </h4>
                </CardHeader>

                <CardBody>
                  <div>
                    <Row className="g-4 mb-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="email">
                              #
                            </th>
                            <th className="sort" data-sort="email">
                              TIN
                            </th>
                            <th className="sort" data-sort="email">
                              Name
                            </th>
                            <th className="sort" data-sort="phone">
                              Mobile Number
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredItems.length > 0 ? (
                            filteredItems.map((data, index) => (
                              <tr key={data.id}>
                                <td>{startIndex + index}</td>
                                <td>{data.tin}</td>
                                <td>{data.name}</td>
                                <td>{data.mobile_number}</td>
                                <td>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic example"
                                  >
                                    <button
                                      type="button"
                                      onClick={() =>
                                        openModalForDetails(
                                          data.id
                                        )
                                      }
                                      className="btn btn-primary btn-sm"
                                    >
                                      <i className="ri-eye-line"></i>
                                    </button>
                                    <button
                                      id="TooltipTop"
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        openModalForEdit(data.id)
                                      }
                                    >
                                      <i className="ri-edit-box-line"></i>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        confirmDelete(data.id, loadClients)
                                      }
                                      className="btn btn-danger btn-sm"
                                    >
                                      <i className="ri-delete-bin-line"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5" style={{ textAlign: "center" }}>
                                No records found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <Pagination
                      paginationInfo={paginationInfo}
                      onNext={handleNextPage}
                      onPrevious={handlePreviousPage}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add/Edit Client Modal */}
      <Modal
        size="lg"
        isOpen={modalOpen}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleModal}>
          {editingClientId ? "Edit Client" : "Add Client"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="clientName">Name</Label>
            <Input
              type="text"
              name="clientName"
              id="clientName"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              invalid={!!errors.clientName}
            />
            {errors.clientName && (
              <div className="invalid-feedback">{errors.clientName}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="clientTin">TIN</Label>
            <Input
              type="text"
              name="clientTin"
              id="clientTin"
              value={clientTin}
              onChange={(e) => setClientTin(e.target.value)}
              invalid={!!errors.clientTin}
            />
            {errors.clientTin && (
              <div className="invalid-feedback">{errors.clientTin}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="clientAddress">Address</Label>
            <Input
              type="text"
              name="clientAddress"
              id="clientAddress"
              value={clientAddress}
              onChange={(e) => setClientAddress(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="clientMobileNumber">Mobile Number</Label>
            <Input
              type="text"
              name="clientMobileNumber"
              id="clientMobileNumber"
              value={clientMobileNumber}
              onChange={(e) => setClientMobileNumber(e.target.value)}
              invalid={!!errors.clientMobileNumber}
            />
            {errors.clientMobileNumber && (
              <div className="invalid-feedback">{errors.clientMobileNumber}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="clientEmail">Email</Label>
            <Input
              type="email"
              name="clientEmail"
              id="clientEmail"
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="clientLogo">Logo</Label>
            <Input
              type="file"
              name="clientLogo"
              id="clientLogo"
              onChange={handleFileChange}
            />
            {logoPreview && (
              <img
                src={logoPreview}
                alt="Preview"
                style={{ width: "100px", height: "100px", objectFit: "contain" }}
              />
            )}
          </FormGroup>
          <FormGroup>
            <Label for="clientIsPrivate">Private</Label>
            <Input
              type="checkbox"
              name="clientIsPrivate"
              id="clientIsPrivate"
              checked={clientIsPrivate}
              onChange={(e) => setClientIsPrivate(e.target.checked)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await createOrUpdateClient(
                  {
                    id: editingClientId,
                    name: clientName,
                    tin: clientTin,
                    address: clientAddress,
                    mobile_number: clientMobileNumber,
                    email: clientEmail,
                    logo: clientLogo,
                    is_private: clientIsPrivate,
                  },
                  toggleModal,
                  loadClients
                );
              } catch (error) {
                console.error("Error saving client data:", error);
              }
            }}
          >
            {editingClientId ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>

      {/* View Client Details Modal */}
      <Modal
        size="lg"
        isOpen={detailsModalOpen}
        toggle={() => setDetailsModalOpen(false)}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => setDetailsModalOpen(false)}>
          Client Details
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="detailName">Name</Label>
            <Input
              type="text"
              name="detailName"
              id="detailName"
              value={clientName}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="detailTin">TIN</Label>
            <Input
              type="text"
              name="detailTin"
              id="detailTin"
              value={clientTin}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="detailAddress">Address</Label>
            <Input
              type="text"
              name="detailAddress"
              id="detailAddress"
              value={clientAddress}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="detailMobileNumber">Mobile Number</Label>
            <Input
              type="text"
              name="detailMobileNumber"
              id="detailMobileNumber"
              value={clientMobileNumber}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="detailEmail">Email</Label>
            <Input
              type="email"
              name="detailEmail"
              id="detailEmail"
              value={clientEmail}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="detailLogo">Logo</Label>
            {clientLogo && (
              <img
                src={clientLogo}
                alt="Logo"
                style={{ width: "100px", height: "100px", objectFit: "contain" }}
              />
            )}
          </FormGroup>
          <FormGroup>
            <Label for="detailIsPrivate">Private</Label>
            <Input
              type="checkbox"
              name="detailIsPrivate"
              id="detailIsPrivate"
              checked={clientIsPrivate}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="detailCreatedAt">Created At</Label>
            <Input
              type="text"
              name="detailCreatedAt"
              id="detailCreatedAt"
              value={formatDate(clientCreatedAt)}
              readOnly
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDetailsModalOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <ConfirmDialog />
    </React.Fragment>
  );
};

export default ClientListTables;
