export const getToken = () => {
  // Retrieve and parse the authUser item from local storage
  const authUser = localStorage.getItem('authUser');
  
  if (authUser) {
    try {
      const { token } = JSON.parse(authUser);
      return token;
    } catch (error) {
      console.error("Error parsing authUser from localStorage:", error);
      return null;
    }
  }
  
  return null;
};

