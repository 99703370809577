import React from "react";
import { Navigate, Route } from "react-router-dom";
import { getToken } from "../services/AuthToken";
// import { enqueueSnackbar } from "notistack";

const AuthProtected = (props) => {
  // Check if token is present in local storage
  const token = getToken();

    // Log the stored token
    // console.log("Stored Token:", token);

  /*
    Redirect to login if no token is found.
  */
  if (!token) {
    // enqueueSnackbar("You must be logged in", {variant: "error"});
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    ); 
  }

  // If token is present, render the children
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
