import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { enqueueSnackbar } from 'notistack';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row
} from "reactstrap";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate hook

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Actions
import { loginUser } from "../../store/actions";

// Reselect
import { createSelector } from "reselect";

const Login = () => {
  // Set document title
  document.title = "Login | Valitan MIS";

    // State to check if the app is running in demo mode
    const [isDemo, setIsDemo] = useState(false);

    // Check if the app is running on localhost
    useEffect(() => {
      if (window.location.hostname === "localhost" || "https://valitantest.webappstechnologies.com/") {
        setIsDemo(true);
      }
    }, []);

  // Initialize Redux dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use useNavigate hook for navigation

  // Formik validation schema and form state management
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password")
    }),
    onSubmit: (values) => {
      // Dispatch login action with user credentials and navigation function;
      dispatch(loginUser(values, navigate));
    }
  });

  // Selector for fetching login error from Redux store
  const loginpage = createSelector(
    (state) => state.login,
    (loginState) => ({
      error: loginState.error
    })
  );

  // Fetch error state from Redux
  const { error } = useSelector(loginpage);
 
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  useEffect(() => {
    document.body.className = "bg-pattern";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    <div className="text-center">
                      <Link to="/">
                        <img
                          src={logo}
                          alt=""
                          height="60"
                          className="auth-logo logo-dark mx-auto mb-2"
                        />
                        <img
                          src={logo}
                          alt=""
                          height="30"
                          className="auth-logo logo-light mx-auto"
                        />
                      </Link>
                    </div>
                    <p className="mb-3 text-center">
                    <strong>Welcome back!</strong><br/>
                      Sign in to continue to Valitan MIS.
                      </p>
                      <p
                      className={`mb-3 text-center ${
                        isDemo ? "text-danger" : "text-primary"
                      }`}
                    >
                      {isDemo ? "(DEMO)" : "(LIVE)"}
                    </p>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email}
                              invalid={
                                validation.touched.email && validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.email}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              value={validation.values.password}
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.password}</div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <Row>
                            <Col>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <label
                                  className="form-label form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </label>
                              </div>
                            </Col>
                            <Col className="col-7">
                              <div className="text-md-end mt-3 mt-md-0">
                                <Link to="/auth-recoverpw" className="">
                                  <i className="mdi mdi-lock"></i> Forgot your
                                  password?
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <div className="d-grid mt-4">
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white">
                  © {new Date().getFullYear()} Valitan Management Information System{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  router: PropTypes.shape({
    navigate: PropTypes.func
  })
};

export default Login;
