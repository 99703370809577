import { confirmDialog } from "primereact/confirmdialog";
import { createCouncil, deleteCouncil, updateCouncil, getAllCouncils } from "../../services/ValitanApiService";
import { enqueueSnackbar } from "notistack";

// Fetch Councils
export const getCouncils = async (page = 0, size = 10) => {
  try {
    const response = await getAllCouncils(page, size);
    if (response.data.status === "success") {
      const field = response.data.data;
      console.log("Halmashauri zote: ", response);
      return {
        councils: field.content,
        paginationInfo: {
          totalElements: field.totalElements,
          totalPages: field.totalPages,
          pageNumber: field.number,
          pageSize: field.size,
          sort: field.sort,
        },
      };
    } else {
      enqueueSnackbar(response.data.message || "Failed to fetch councils.", { variant: "error" });
      return { councils: [], paginationInfo: {} };
    }
  } catch (error) {
    console.error("Error fetching councils:", error);
    enqueueSnackbar("Error fetching councils. Please try again.", { variant: "error" });
    return { councils: [], paginationInfo: {} };
  }
};


// Delete council
export const deleteACouncil = async (id, loadCouncils) => {
  try {
    const response = await deleteCouncil(id);
    if (response.data.status === "success") {
      enqueueSnackbar(response.data.message, { variant: "success" });
      await loadCouncils();
    } else {
      enqueueSnackbar(response.data.message || "Failed to delete council.", { variant: "error" });
    }
  } catch (error) {
    enqueueSnackbar("Failed to delete council. Please try again.", { variant: "error" });
    console.error(error);
  }
};

export const confirmDelete = (id, loadCouncils) => {
  confirmDialog({
    message: "Are you sure you want to delete this council?",
    header: "Confirmation",
    icon: "pi pi-question-circle",
    accept: () => deleteACouncil(id, loadCouncils),
    reject: () => { },
  });
};

// Validation on create or update
export const validateCouncilForm = (councilName, selectedDistrict, setErrors) => {
  let valid = true;
  const errorsCopy = { councilName: "", district: "" };

  if (councilName.trim()) {
    errorsCopy.councilName = "";
  } else {
    errorsCopy.councilName = "Council Name is required";
    valid = false;
  }

  if (selectedDistrict && selectedDistrict.value) {
    errorsCopy.district = "";
  } else {
    errorsCopy.district = "District is required";
    valid = false;
  }

  setErrors(errorsCopy);
  return valid;
};


// Create or update council
export const createOrUpdateCouncil = async (
  event,
  councilName,
  selectedDistrict,
  editingCouncilId,
  loadCouncils,
  toggleModal,
  setErrors
) => {
  event.preventDefault();

  if (!validateCouncilForm(councilName, selectedDistrict, setErrors)) {
    return;
  }

  // Prepare payload with correct structure
  const councilData = { 
    name: councilName, 
    district: { id: selectedDistrict.value } // Include selectedDistrict
  };

  try {
    const response = editingCouncilId
      ? await updateCouncil(editingCouncilId, councilData)
      : await createCouncil(councilData);

    if (response.data.status === "success") {
      enqueueSnackbar(response.data.message, { variant: "success" });
      toggleModal();
      await loadCouncils();
    } else {
      enqueueSnackbar(response.data.message || "Failed to save council data.", { variant: "error" });
    }
  } catch (error) {
    console.error("Error saving council data:", error);
    enqueueSnackbar("Error saving council data. Please try again.", { variant: "error" });
  }
};

