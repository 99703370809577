import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label } from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { changeUserPassword } from "../../services/ValitanApiService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
    const [user, setUser] = useState(null);  // To store the logged-in user details
    const [loading, setLoading] = useState(true);  // To handle loading state
    const [error, ] = useState('');
    const navigate = useNavigate(); 

    useEffect(() => {
        // Retrieve and parse user details from local storage
        const authUser = localStorage.getItem("authUser");
        if (authUser) {
            try {
                const { user } = JSON.parse(authUser);
                setUser(user);  // Store the user details
            } catch (error) {
                console.error("Error parsing authUser from localStorage:", error);
                setUser(null);  // Handle error or reset user
            }
        }
        setLoading(false);
    }, []);

    // Formik initialization
    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .min(6, 'Password should be at least 6 characters long')
                .required('New password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword')], 'Passwords must match')
                .required('Please confirm your new password')
        }),
        onSubmit: async (values) => {
            if (!user) {
                enqueueSnackbar('User not found. Please log in again.', {variant: 'error'});
                return;
            }
            try {
                await changeUserPassword(user.id, {
                    password: values.newPassword  // Send password in the required format
                });
                enqueueSnackbar('Password updated successfully', {variant: 'success'});
                
                // Remove token from local storage
                localStorage.removeItem("authUser");
                
                  // Redirect the user to the login page after logout
                  setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } catch (err) {
                enqueueSnackbar('Error updating password. Please try again.', {variant: 'error'});
            }
        }
    });

    if (loading) {
        return <p>Loading...</p>; // You can replace this with a spinner or loading indicator
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="User Profile" breadcrumbItem="Change password"/>
                    <Col lg={8}>
                        <Card>
                            <CardBody>
                                <Form className="form-horizontal" onSubmit={formik.handleSubmit}>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">New password</Label>
                                        <input
                                            name="newPassword"
                                            type="password"
                                            placeholder="Enter new password"
                                            className={`form-control ${
                                                formik.touched.newPassword && formik.errors.newPassword
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            value={formik.values.newPassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.newPassword && formik.errors.newPassword && (
                                            <div className="invalid-feedback">
                                                {formik.errors.newPassword}
                                            </div>
                                        )}
                                    </div>

                                    <div className="form-group mb-3">
                                        <Label className="form-label">Confirm new password</Label>
                                        <input
                                            name="confirmPassword"
                                            type="password"
                                            placeholder="Confirm new password"
                                            className={`form-control ${
                                                formik.touched.confirmPassword && formik.errors.confirmPassword
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            value={formik.values.confirmPassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                            <div className="invalid-feedback">
                                                {formik.errors.confirmPassword}
                                            </div>
                                        )}
                                    </div>

                                    {error && <div className="alert alert-danger">{error}</div>}

                                    <div className="text-center mt-4">
                                        <Button type="submit" color="primary">
                                            Update password
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ChangePassword;
