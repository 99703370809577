import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Pagination from "../../components/Pagination/Pagination";
import { getCouncil } from "../../services/ValitanApiService";
import Select from "react-select";
import {
  confirmDelete,
  createOrUpdateCouncil,
  getCouncils as fetchCouncils,
} from "../Councils/CouncilService";
import { getDistricts as fetchDistricts } from "../Districts/DistrictService";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { ConfirmDialog } from "primereact/confirmdialog";

const CouncilListTables = () => {
  document.title = "Councils | Valitan";

  const [councils, setCouncils] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [councilName, setCouncilName] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [errors, setErrors] = useState({ councilName: "", region: "" });
  const [editingCouncilId, setEditingCouncilId] = useState(null);
  const [paginationInfo, setPaginationInfo] = useState({
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 10,
    sort: {},
  });

  useEffect(() => {
    loadCouncils(0, paginationInfo.pageSize);
    loadDistricts(); // Fetch districts when component mounts
  }, [paginationInfo.pageSize]);

  const loadCouncils = async (page = 0, size = 10) => {
    const { councils, paginationInfo } = await fetchCouncils(page, size);
    setCouncils(councils);
    setPaginationInfo(paginationInfo);
  };

  const loadDistricts = async () => {
    try {
      const response = await fetchDistricts();
      const regionOptions = response.districts.map((region) => ({
        value: region.id,
        label: region.name,
      }));
      setDistricts(regionOptions);
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  const handleNextPage = () => {
    if (paginationInfo.pageNumber < paginationInfo.totalPages - 1) {
      loadCouncils(paginationInfo.pageNumber + 1, paginationInfo.pageSize);
    }
  };

  const handlePreviousPage = () => {
    if (paginationInfo.pageNumber > 0) {
      loadCouncils(paginationInfo.pageNumber - 1, paginationInfo.pageSize);
    }
  };

  const openModalForEdit = async (id) => {
    setEditingCouncilId(id);
    try {
      const response = await getCouncil(id);
      if (response) {
        const field = response.data.data;
        setCouncilName(field.name);
        setSelectedDistrict({
          value: field.district.id,
          label: field.district.name,
        });
        setModalOpen(true);
      } else {
        console.error("Council data is undefined");
      }
    } catch (error) {
      console.error("Error fetching Council data:", error);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setCouncilName("");
      setSelectedDistrict(null);
      setErrors({
        councilName: "",
        district: "",
      });
      setEditingCouncilId(null);
    }
  };

  const toggleFileUploadModal = () => {
    setFileUploadModalOpen(!fileUploadModalOpen);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedDistrict(selectedOption);
    setErrors((prevErrors) => ({ ...prevErrors, district: "" })); // Clear the error when a district is selected
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    createOrUpdateCouncil(
      event,
      councilName, // Pass councilName directly
      selectedDistrict, // Pass selectedDistrict directly
      editingCouncilId,
      loadCouncils,
      toggleModal,
      setErrors
    );
  };

  const handleFileUpload = (event) => {
    event.preventDefault();
    const file = event.target.fileInput.files[0];
    if (file) {
      // Handle file upload logic here
      console.log("File selected:", file);
    }
  };

  const startIndex = paginationInfo.pageNumber * paginationInfo.pageSize + 1;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Councils setup" breadcrumbItem="Councils" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    <Button
                      color="primary"
                      className="add-btn float-end ms-2"
                      id="TooltipTop"
                      onClick={toggleFileUploadModal}
                    >
                      <i className="ri-upload-cloud-2-line align-bottom me-1"></i> Bulk upload
                    </Button>
                    <Button
                      color="primary"
                      className="add-btn float-end"
                      id="TooltipTop"
                      onClick={toggleModal}
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add
                    </Button>
                  </h4>
                </CardHeader>

                <CardBody>
                  <div>
                    <Row className="g-4 mb-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="email">
                              #
                            </th>
                            <th className="sort" data-sort="email">
                              District
                            </th>
                            <th className="sort" data-sort="email">
                              Council
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {councils.length > 0 ? (
                            councils.map((council, index) => (
                              <tr key={council.id}>
                                <td>{startIndex + index}</td>
                                <td>{council.district.name}</td>
                                <td>{council.name}</td>
                                <td>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic example"
                                  >
                                    <button
                                      id="TooltipTop"
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        openModalForEdit(council.id)
                                      }
                                    >
                                      <i className="ri-edit-box-line"></i>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        confirmDelete(council.id, loadCouncils)
                                      }
                                      className="btn btn-danger btn-sm"
                                    >
                                      <i className="ri-delete-bin-line"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>
                                No records found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      paginationInfo={paginationInfo}
                      handleNextPage={handleNextPage}
                      handlePreviousPage={handlePreviousPage}
                      onPageChange={(pageNumber, pageSize) => loadCouncils(pageNumber, pageSize)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ConfirmDialog />

      <Modal isOpen={modalOpen} toggle={toggleModal} backdrop={"static"}>
        <ModalHeader toggle={toggleModal}>
          {editingCouncilId ? "Edit council" : "Add council"}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="district">District</Label>
              <Select
                name="region"
                id="district"
                placeholder="Select a District"
                value={selectedDistrict}
                onChange={handleSelectChange}
                options={districts}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: errors.district ? '#dc3545' : base.borderColor,
                    '&:hover': {
                      borderColor: errors.district ? '#dc3545' : base['&:hover'].borderColor,
                    },
                  }),
                }}
              />
              {errors.district && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {errors.district}
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="councilName">Name</Label>
              <Input
                type="text"
                name="councilName"
                id="councilName"
                placeholder="Enter Name"
                value={councilName}
                onChange={(e) => setCouncilName(e.target.value)}
                invalid={!!errors.councilName}
              />
              {errors.councilName && (
                <div className="invalid-feedback">{errors.councilName}</div>
              )}
            </FormGroup>
            <Button type="submit" color="primary" className="float-end">
              {editingCouncilId ? "Update" : "Save"}
            </Button>
          </form>
        </ModalBody>
      </Modal>

      <Modal isOpen={fileUploadModalOpen} toggle={toggleFileUploadModal} backdrop={"static"}>
        <ModalHeader toggle={toggleFileUploadModal}>Bulk Councils Upload</ModalHeader>
        <ModalBody>
          <form onSubmit={handleFileUpload}>
            <FormGroup>
              <Label for="fileInput">Select File</Label>
              <Input
                type="file"
                id="fileInput"
                name="fileInput"
                accept=".csv,.xlsx"
                required
              />
            </FormGroup>
            <Button type="submit" color="primary" className="float-end">
              Upload
            </Button>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CouncilListTables;
