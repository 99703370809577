import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

// Reusable form field component
const FormField = ({ id, name, label, placeholder, type = "text", value, onChange, error, disabled, inputMode, onKeyPress }) => (
    <FormGroup>
        <Label htmlFor={id}>{label}</Label>
        <Input
            id={id}
            name={name}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={onChange}
            invalid={!!error}
            disabled={disabled} // Pass the disabled prop here
            inputMode={inputMode} // Set input mode for numeric fields
            onKeyPress={onKeyPress} // Handle key press for validation
        />
        {error && <div className="invalid-feedback">{error}</div>}
    </FormGroup>
);

export default FormField;
