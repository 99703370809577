import { confirmDialog } from "primereact/confirmdialog";
import { createClient, deleteClient, updateClient, getAllClients, getClient } from "../../services/ValitanApiService";
import { enqueueSnackbar } from "notistack";

// Fetch Clients
export const getClients = async (page = 0, size = 10) => {
  try {
    const response = await getAllClients(page, size);
    if (response.data.status === "success") {
      const field = response.data.data;
      // console.log("Clients fetched:", field);
      return {
        clients: field.content,
        paginationInfo: {
          totalElements: field.totalElements,
          totalPages: field.totalPages,
          pageNumber: field.number,
          pageSize: field.size,
          sort: field.sort,
        },
      };
    } else {
      enqueueSnackbar(response.data.message || "Failed to fetch clients.", { variant: 'error' });
      return { clients: [], paginationInfo: {} };
    }
  } catch (error) {
    enqueueSnackbar("Error fetching clients. Please try again.", { variant: "error" });
    return { clients: [], paginationInfo: {} };
  }
};

// Delete Client
export const deleteAClient = async (id, loadClients) => {
  try {
    const response = await deleteClient(id);
    if (response.data.status === "success") {
      enqueueSnackbar(response.data.message, { variant: "success" });
      await loadClients();
    } else {
      enqueueSnackbar(response.data.message || "Failed to delete client.", { variant: "error" });
    }
  } catch (error) {
    enqueueSnackbar("Failed to delete client. Please try again.", { variant: "error" });
    console.error(error);
  }
};

export const confirmDelete = (id, loadClients) => {
  confirmDialog({
    message: "Are you sure you want to delete this client?",
    header: "Confirmation",
    icon: "pi pi-question-circle",
    accept: () => deleteAClient(id, loadClients),
    reject: () => { },
  });
};

// Fetch a single client's details
export const viewClientDetails = async (
  clientId,
  setLoading,
  setClientName,
  setClientTin,
  setClientAddress,
  setClientMobileNumber,
  setClientEmail,
  setClientLogo,
  setClientIsPrivate,
  setClientCreatedAt,
  setModalOpen
) => {
  setLoading(true);

  try {
    const response = await getClient(clientId);

    if (response.data.status === "success" && response.data) {
      const client = response.data.data;
      setClientName(client.name);
      setClientTin(client.tin);
      setClientAddress(client.address);
      setClientMobileNumber(client.mobile_number);
      setClientEmail(client.email);
      setClientLogo(client.logo);
      setClientIsPrivate(client.is_private);
      setClientCreatedAt(client.created_at);
      setModalOpen(true);  // Open the modal once the data is set
    } else {
      enqueueSnackbar(response.message || "Client data is undefined", { variant: "error" });
    }
  } catch (error) {
    enqueueSnackbar("Error fetching client details. Please try again.", { variant: "error" });
  } finally {
    setLoading(false); // Stop loading indicator after fetching data
  }
};

// Validation on create or update
export const validateClientForm = (clientName, clientTin, clientMobileNumber, clientIsPrivate, setErrors) => {
  let valid = true;
  const errorsCopy = { clientName: "", clientTin: "", clientMobileNumber: "", clientIsPrivate: "" };

  if (clientName.trim()) {
    errorsCopy.clientName = "";
  } else {
    errorsCopy.clientName = "Name is required";
    valid = false;
  }
  if (clientTin.trim()) {
    errorsCopy.clientTin = "";
  } else {
    errorsCopy.clientTin = "TIN is required";
    valid = false;
  }
  if (clientMobileNumber.trim()) {
    errorsCopy.clientMobileNumber = "";
  } else {
    errorsCopy.clientMobileNumber = "Mobile number is required";
    valid = false;
  }
  if (clientIsPrivate.trim()) {
    errorsCopy.clientIsPrivate = "";
  } else {
    errorsCopy.clientIsPrivate = "Ownership type is required";
    valid = false;
  }

  setErrors(errorsCopy);
  return valid;
};

// Create or update client
export const createOrUpdateClient = async (event, clientName, clientTin, clientMobileNumber, clientAddress, clientEmail, clientLogo, clientIsPrivate, editingClientId, loadClients, toggleModal, setErrors) => {
  event.preventDefault();

  if (!validateClientForm(clientName, clientTin, clientMobileNumber, clientIsPrivate, setErrors)) {
    return;
  }

  const clientData = { name: clientName, tin: clientTin, address: clientAddress, mobile_number: clientMobileNumber, email: clientEmail, logo: clientLogo, is_private: clientIsPrivate };

  try {
    const response = editingClientId ? await updateClient(editingClientId, clientData) : await createClient(clientData);

    if (response.data.status === "success") {
      enqueueSnackbar(response.data.message, { variant: "success" });
      toggleModal();
      await loadClients();
    } else {
      enqueueSnackbar(response.data.message || "Failed to save client data.", { variant: "error" });
    }
  } catch (error) {
    enqueueSnackbar("Error saving client data. Please try again.", { variant: "error" });
  }
};
