import { confirmDialog } from "primereact/confirmdialog";
import { createRegion, deleteRegion, updateRegion, getAllRegions } from "../../services/ValitanApiService";
import { enqueueSnackbar } from "notistack";

// Fetch Regions
export const getRegions = async (page = 0, size = 10) => {
  try {
    const response = await getAllRegions(page, size);
    if (response.data.status === "success") {
      const field = response.data.data;
      // console.log("Regions fetched:", response);
      return {
        regions: field.content,
        paginationInfo: {
          totalElements: field.totalElements,
          totalPages: field.totalPages,
          pageNumber: field.number,
          pageSize: field.size,
          sort: field.sort,
        },
      };
    } else {
      enqueueSnackbar(response.data.message || "Failed to fetch regions.", { variant: "error" });
      return { regions: [], paginationInfo: {} };
    }
  } catch (error) {
    console.error("Error fetching regions:", error);
    enqueueSnackbar("Error fetching regions. Please try again.", { variant: "error" });
    return { regions: [], paginationInfo: {} };
  }
};

// Delete region
export const deleteARegion = async (id, loadRegions) => {
  try {
    const response = await deleteRegion(id);
    if (response.data.status === "success") {
      enqueueSnackbar(response.data.message, { variant: "success" });
      await loadRegions();
    } else {
      enqueueSnackbar(response.data.message || "Failed to delete region.", { variant: "error" });
    }
  } catch (error) {
    enqueueSnackbar("Failed to delete region. Please try again.", { variant: "error" });
    console.error(error);
  }
};

export const confirmDelete = (id, loadRegions) => {
  confirmDialog({
    message: "Are you sure you want to delete this region?",
    header: "Confirmation",
    icon: "pi pi-question-circle",
    accept: () => deleteARegion(id, loadRegions),
    reject: () => { },
  });
};

// Validation on create or update
export const validateRegionForm = (regionName, setErrors) => {
  let valid = true;
  const errorsCopy = { regionName: "" };

  if (regionName.trim()) {
    errorsCopy.regionName = "";
  } else {
    errorsCopy.regionName = "Name is required";
    valid = false;
  }

  setErrors(errorsCopy);
  return valid;
};

// Create or update region
export const createOrUpdateRegion = async (event, regionName, editingRegionId, loadRegions, toggleModal, setErrors) => {
  event.preventDefault();

  if (!validateRegionForm(regionName, setErrors)) {
    return;
  }

  const regionData = { name: regionName };

  try {
    const response = editingRegionId ? await updateRegion(editingRegionId, regionData) : await createRegion(regionData);

    if (response.data.status === "success") {
      enqueueSnackbar(response.data.message, { variant: "success" });
      toggleModal();
      await loadRegions();
    } else {
      enqueueSnackbar(response.data.message || "Failed to save region data.", { variant: "error" });
    }
  } catch (error) {
    console.error("Error saving region data:", error);
    enqueueSnackbar("Error saving region data. Please try again.", { variant: "error" });
  }
};
