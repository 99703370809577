import axios from 'axios';
import { getToken } from './AuthToken';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

// Add a request interceptor to add the authorization token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    // console.log('Token hizi hapa:', token); 
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// API calls

// Roles
export const getAllRoles = (page = 0, size = 10) =>
  axiosInstance.get('/roles', {
    params: {
      page: page,
      size: size,
      sort: 'createdAt,desc',
    },
  });

export const getRole = (roleId) => axiosInstance.get(`/roles/${roleId}`);
export const createRole = (role) => axiosInstance.post('/roles', role);
export const updateRole = (roleId, role) => axiosInstance.put(`/roles/${roleId}`, role);
export const deleteRole = (roleId) => axiosInstance.delete(`/roles/${roleId}`);

// Clients
export const getAllClients = (page = 0, size = 10) =>
  axiosInstance.get('/clients', {
    params: {
      page: page,
      size: size,
      sort: 'createdAt,desc',
    },
  });

export const getClient = (clientId) => axiosInstance.get(`/clients/${clientId}`);
export const createClient = (client) => axiosInstance.post('/clients', client);
export const updateClient = (clientId, client) => axiosInstance.put(`/clients/${clientId}`, client);
export const deleteClient = (clientId) => axiosInstance.delete(`/clients/${clientId}`);

// Companies
export const getAllCompanies = (page = 0, size = 10) =>
  axiosInstance.get('/companies', {
    params: {
      page: page,
      size: size,
      sort: 'createdAt,desc',
    },
  });

export const getCompany = (companyId) => axiosInstance.get(`/companies/${companyId}`);
export const createCompany = (company) => axiosInstance.post('/companies', company);
export const updateCompany = (companyId, company) => axiosInstance.put(`/companies/${companyId}`, company);
export const deleteCompany = (companyId) => axiosInstance.delete(`/companies/${companyId}`);

// Regions
export const getAllRegions = (page = 0, size = 10) =>
  axiosInstance.get('/regions', {
    params: {
      page: page,
      size: size,
      sort: 'createdAt,desc',
    },
  });

export const getRegion = (regionId) => axiosInstance.get(`/regions/${regionId}`);
export const createRegion = (region) => axiosInstance.post('/regions', region);
export const uploadRegions = (formData) => axiosInstance.post('/regions/upload', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
export const updateRegion = (regionId, region) => axiosInstance.put(`/regions/${regionId}`, region);
export const deleteRegion = (regionId) => axiosInstance.delete(`/regions/${regionId}`);

// Districts
export const getAllDistricts = (page = 0, size = 10) =>
  axiosInstance.get('/districts', {
    params: {
      page: page,
      size: size,
      sort: 'createdAt,desc',
    },
  });

export const getDistrict = (districtId) => axiosInstance.get(`/districts/${districtId}`);
export const createDistrict = (district) => axiosInstance.post('/districts', district);
export const updateDistrict = (districtId, district) => axiosInstance.put(`/districts/${districtId}`, district);
export const deleteDistrict = (districtId) => axiosInstance.delete(`/districts/${districtId}`);

// Users
export const getAllUsers = (page = 0, size = 10) =>
  axiosInstance.get('/users', {
    params: {
      page: page,
      size: size,
      sort: 'createdAt,desc',
    },
  });

export const getUser = (userId) => axiosInstance.get(`/users/${userId}`);
export const createUser = (user) => axiosInstance.post('/users/register', user);
export const updateUser = (userId, user) => axiosInstance.put(`/users/${userId}`, user);
export const deleteUser = (userId) => axiosInstance.delete(`/users/${userId}`);
export const resetUser = (userId) => axiosInstance.put(`/users/reset/${userId}`);
export const blockActivateUser = (userId) => axiosInstance.put(`/users/block-activate/${userId}`);
export const changeUserPassword = (userId, { password }) => {
  return axiosInstance.put(`/users/change-password/${userId}`, {
    password
  });
};

// Councils
export const getAllCouncils = (page = 0, size = 10) =>
  axiosInstance.get('/councils', {
    params: {
      page: page,
      size: size,
      sort: 'createdAt,desc',
    },
  });

export const getCouncil = (councilId) => axiosInstance.get(`/councils/${councilId}`);
export const createCouncil = (council) => axiosInstance.post('/councils', council);
export const updateCouncil = (councilId, council) => axiosInstance.put(`/councils/${councilId}`, council);
export const deleteCouncil = (councilId) => axiosInstance.delete(`/councils/${councilId}`);
