import { deleteRole, createRole, updateRole, getAllRoles } from "../../../services/ValitanApiService";
import { enqueueSnackbar } from "notistack";
import { confirmDialog } from "primereact/confirmdialog";

// Fetch role
export const getRoles = async (page = 0, size = 10) => {
    try {
        const response = await getAllRoles(page, size);
        if (response.status === 200) {
            const respo = response.data;
            // console.log("Roles fetched:", response);
            return {
                roles: respo.content,
                paginationInfo: {
                    totalElements: respo.totalElements,
                    totalPages: respo.totalPages,
                    pageNumber: respo.number,
                    pageSize: respo.size,
                    sort: respo.sort
                }
            };
        } else {
            enqueueSnackbar(response.message || "Failed to fetch roles.", { variant: "error" });
            return { roles: [], paginationInfo: {} };
        }
    } catch (error) {
        console.error("Error fetching roles:", error);
        enqueueSnackbar("Error fetching roles. Please try again.", { variant: "error" });
        return { roles: [], paginationInfo: {} };
    }
};

// Delete role
export const deleteARole = async (id, loadRoles) => {
    try {
        const response = await deleteRole(id);
        if (response.status === 200) {
            enqueueSnackbar('Role deleted successfully!', { variant: "success" });
            await loadRoles();
        } else {
            enqueueSnackbar(response.message || "Failed to delete role.", { variant: "error" });
        }
    } catch (error) {
        enqueueSnackbar('Failed to delete role. Please try again.', { variant: "error" });
        console.error(error);
    }
};

export const confirmDelete = (id, loadRoles) => {
    confirmDialog({
        message: 'Are you sure you want to delete this role?',
        header: 'Confirmation',
        icon: 'pi pi-question-circle',
        accept: () => deleteARole(id, loadRoles),
        reject: () => {},
    });
};

// Validation on create or update
export const validateForm = (roleName, roleCode, setErrors) => {
    let valid = true;
    const errorsCopy = { roleName: '', roleCode: '' };

    if (roleName.trim()) {
        errorsCopy.roleName = '';
    } else {
        errorsCopy.roleName = 'The name is required';
        valid = false;
    }
    if (roleCode.trim()) {
        errorsCopy.roleCode = '';
    } else {
        errorsCopy.roleCode = 'The code is required';
        valid = false;
    }

    setErrors(errorsCopy);
    return valid;
};

// Create or Update role
export const createOrUpdateRole = async (evt, roleName, roleCode, editingRoleId, loadRoles, toggleModal, setErrors) => {
    evt.preventDefault();

    if (validateForm(roleName, roleCode, setErrors)) {
        const role = {
            name: roleName,
            code: roleCode
        };
        try {
            if (editingRoleId) {
                await updateRole(editingRoleId, role);
                enqueueSnackbar('Role updated successfully!', { variant: "success" });
            } else {
                await createRole(role);
                enqueueSnackbar('Role created successfully!', { variant: "success" });
            }
            toggleModal();
            await loadRoles();
        } catch (error) {
            enqueueSnackbar('Failed to save role. Please try again.', { variant: "error" });
            console.error(error);
        }
    }
};
