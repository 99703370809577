import React from "react";
import { confirmDialog } from "primereact/confirmdialog";

export const ConfirmBlockDialog = ({ userId, isBlocked, onAccept }) => {
    const actionType = isBlocked === 'Yes' ? 'unblock' : 'block';
    const message = actionType === 'block'
        ? "Are you sure you want to block this user?"
        : "Are you sure you want to unblock this user?";

    const showDialog = () => {
        confirmDialog({
            message,
            header: "Confirmation",
            icon: "pi pi-question-circle",
            accept: () => onAccept(userId, actionType),
            reject: () => { },
        });
    };

    return (
        <button
            type="button"
            className={`btn btn-sm ${isBlocked === 'Yes' ? 'btn-primary' : 'btn-danger'}`}
            onClick={showDialog}
        >
            {isBlocked === 'Yes' ? (
                <i className="mdi mdi-account-check"></i>
            ) : (
                <i className="mdi mdi-block-helper"></i>
            )}
        </button>
    );
};

export const ConfirmResetDialog = ({ userId, onAccept, isBlocked }) => {
    const message = "Are you sure you want to reset this user's password?";

    const showDialog = () => {
        confirmDialog({
            message,
            header: "Confirmation",
            icon: "pi pi-question-circle",
            accept: () => onAccept(userId),
            reject: () => { },
        });
    };

    return (
        <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={showDialog}
            disabled={isBlocked === 'Yes'} // Disable button if the user is banned
        >
            <i className="mdi mdi-lock-reset"></i>
        </button>
    );
};

