import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Pagination from "../../components/Pagination/Pagination"; // Import the reusable Pagination component
import { getRegion, uploadRegions } from "../../services/ValitanApiService";
import {
  // confirmDelete,
  createOrUpdateRegion,
  getRegions as fetchRegions,
} from "../Regions/RegionService";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import { enqueueSnackbar } from "notistack";

const RegionListTables = () => {
  document.title = "Regions | Valitan";

  const [regions, setRegions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false); // State for upload modal
  const [regionName, setRegionName] = useState("");
  const [errors, setErrors] = useState({ regionName: "" });
  const [editingRegionId, setEditingRegionId] = useState(null);
  const [paginationInfo, setPaginationInfo] = useState({
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 10,
    sort: {},
  });
  const [selectedFile, setSelectedFile] = useState(null); // State to hold selected file

  useEffect(() => {
    loadRegions(0, paginationInfo.pageSize); // Load initial regions data
  }, [paginationInfo.pageSize]);

  const loadRegions = async (page = 0, size = 10) => {
    const { regions, paginationInfo } = await fetchRegions(page, size);
    setRegions(regions);
    setPaginationInfo(paginationInfo);
  };

  const handleNextPage = () => {
    if (paginationInfo.pageNumber < paginationInfo.totalPages - 1) {
      loadRegions(paginationInfo.pageNumber + 1, paginationInfo.pageSize);
    }
  };

  const handlePreviousPage = () => {
    if (paginationInfo.pageNumber > 0) {
      loadRegions(paginationInfo.pageNumber - 1, paginationInfo.pageSize);
    }
  };

  const openModalForEdit = async (id) => {
    setEditingRegionId(id);
    try {
      const response = await getRegion(id);
      if (response) {
        const field = response.data.data;
        setRegionName(field.name);
        setModalOpen(true);
      } else {
        console.error("Region data is undefined");
      }
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setRegionName("");
      setErrors({ regionName: "" });
      setEditingRegionId(null);
    }
  };

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);
    setSelectedFile(null); // Clear selected file when closing modal
  };

  const handleFileChange = (event) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      enqueueSnackbar("No file selected.", { variant: 'error' });
      return; // Early return if no file is selected
    }

    try {
      // Create FormData object
      const formData = new FormData();
      formData.append('file', selectedFile); // Append the file to the FormData

      // Send FormData to the server
      const response = await uploadRegions(formData);
      if(response.data.status === 'success'){
        enqueueSnackbar(response.data.message, { variant: 'success' });
        loadRegions(paginationInfo.pageNumber, paginationInfo.pageSize); // Reload regions
        toggleUploadModal(); 
      } if(response.data.status === 'failed'){
        enqueueSnackbar(response.data.message, { variant: 'success' });
        loadRegions(paginationInfo.pageNumber, paginationInfo.pageSize); // Reload regions
        toggleUploadModal();
      }

    } catch (error) {
      enqueueSnackbar("Something went wrong, try again", { variant: 'error' });
      // Handle error (e.g., display an error message)
    }
  };

  const startIndex = paginationInfo.pageNumber * paginationInfo.pageSize + 1;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Regions setup" breadcrumbItem="Regions" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    <Button
                      color="primary"
                      className="add-btn float-end ms-2"
                      id="TooltipTop"
                      onClick={toggleUploadModal}
                    >
                      <i className="ri-upload-cloud-2-line align-bottom me-1"></i> Upload regions
                    </Button>
                    {/* <Button
                      color="primary"
                      className="add-btn float-end"
                      id="TooltipTop"
                      onClick={toggleModal}
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add
                    </Button> */}
                  </h4>
                </CardHeader>

                <CardBody>
                  <div>
                    <Row className="g-4 mb-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table className="table align-middle table-nowrap" id="customerTable">
                        <thead className="table-light">
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {regions.length > 0 ? (
                            regions.map((region, index) => (
                              <tr key={region.id}>
                                <td>{startIndex + index}</td>
                                <td>{region.name}</td>
                                <td>
                                  <div className="btn-group" role="group" aria-label="Basic example">
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() => openModalForEdit(region.id)}
                                    >
                                      <i className="ri-edit-box-line"></i>
                                    </button>
                                    {/* <button
                                      type="button"
                                      onClick={() => confirmDelete(region.id, loadRegions)}
                                      className="btn btn-danger btn-sm"
                                    >
                                      <i className="ri-delete-bin-line"></i>
                                    </button> */}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3" style={{ textAlign: "center" }}>
                                No records found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {/* Reusable Pagination Component */}
                    <Pagination
                      paginationInfo={paginationInfo}
                      handleNextPage={handleNextPage}
                      handlePreviousPage={handlePreviousPage}
                      onPageChange={(pageNumber, pageSize) => loadRegions(pageNumber, pageSize)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ConfirmDialog */}
      <ConfirmDialog />

      {/* Add Region Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal} backdrop={"static"}>
        <ModalHeader toggle={toggleModal}>
          {editingRegionId ? "Edit Region" : "Add Region"}
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={(evt) =>
              createOrUpdateRegion(evt, regionName, editingRegionId, loadRegions, toggleModal, setErrors)
            }
          >
            <FormGroup>
              <Label for="regionName">Name</Label>
              <Input
                type="text"
                name="regionName"
                id="regionName"
                placeholder="Enter Name"
                value={regionName}
                onChange={(e) => setRegionName(e.target.value)}
                invalid={!!errors.regionName}
              />
              {errors.regionName && <div className="invalid-feedback">{errors.regionName}</div>}
            </FormGroup>
            <Button type="submit" color="primary" className="float-end">
              {editingRegionId ? "Update" : "Save"}
            </Button>
          </form>
        </ModalBody>
      </Modal>

      {/* Upload Modal */}
      <Modal isOpen={uploadModalOpen} toggle={toggleUploadModal} backdrop={"static"}>
        <ModalHeader toggle={toggleUploadModal}>Upload Regions</ModalHeader>
        <ModalBody>
          <form onSubmit={handleFileUpload}>
            <FormGroup>
              <Label for="fileUpload">Upload File</Label>
              <Input
                type="file"
                name="file"
                id="fileUpload"
                onChange={handleFileChange}
                accept=".xlsx, .xls, .csv"
              />
            </FormGroup>
            <Button type="submit" color="primary" className="float-end">
              Upload
            </Button>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default RegionListTables;
