import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Pagination from "../../components/Pagination/Pagination";
import { getDistrict } from "../../services/ValitanApiService";
import Select from "react-select";
import {
  confirmDelete,
  createOrUpdateDistrict,
  getDistricts as fetchDistricts,
} from "../Districts/DistrictService";
import { getRegions as fetchRegions } from "../Regions/RegionService";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { ConfirmDialog } from "primereact/confirmdialog";

const DistrictListTables = () => {
  document.title = "Districts | Valitan";

  const [districts, setDistricts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [districtName, setDistrictName] = useState("");
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [regions, setRegions] = useState([]);
  const [errors, setErrors] = useState({ districtName: "", region: "" });
  const [editingDistrictId, setEditingDistrictId] = useState(null);
  const [paginationInfo, setPaginationInfo] = useState({
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 10,
    sort: {},
  });

  useEffect(() => {
    loadDistricts(0, paginationInfo.pageSize);
    loadRegions(); // Fetch regions when component mounts
  }, [paginationInfo.pageSize]);

  const loadDistricts = async (page = 0, size = 10) => {
    const { districts, paginationInfo } = await fetchDistricts(page, size);
    setDistricts(districts);
    setPaginationInfo(paginationInfo);
  };

  const loadRegions = async () => {
    try {
      const response = await fetchRegions();
      const regionOptions = response.regions.map((region) => ({
        value: region.id,
        label: region.name,
      }));
      setRegions(regionOptions);
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  const handleNextPage = () => {
    if (paginationInfo.pageNumber < paginationInfo.totalPages - 1) {
      loadDistricts(paginationInfo.pageNumber + 1, paginationInfo.pageSize);
    }
  };

  const handlePreviousPage = () => {
    if (paginationInfo.pageNumber > 0) {
      loadDistricts(paginationInfo.pageNumber - 1, paginationInfo.pageSize);
    }
  };

  const openModalForEdit = async (id) => {
    setEditingDistrictId(id);
    try {
      const response = await getDistrict(id);
      if (response) {
        const field = response.data.data;
        setDistrictName(field.name);
        setSelectedRegion({
          value: field.region.id,
          label: field.region.name,
        });
        setModalOpen(true);
      } else {
        console.error("District data is undefined");
      }
    } catch (error) {
      console.error("Error fetching District data:", error);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setDistrictName("");
      setSelectedRegion(null);
      setErrors({
        districtName: "",
        region: "",
      });
      setEditingDistrictId(null);
    }
  };

  const toggleFileUploadModal = () => {
    setFileUploadModalOpen(!fileUploadModalOpen);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedRegion(selectedOption);
    setErrors((prevErrors) => ({ ...prevErrors, region: "" })); // Clear the error when a region is selected
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    createOrUpdateDistrict(
      event,
      districtName, // Pass districtName directly
      selectedRegion, // Pass selectedRegion directly
      editingDistrictId,
      loadDistricts,
      toggleModal,
      setErrors
    );
  };

  const handleFileUpload = (event) => {
    event.preventDefault();
    const file = event.target.fileInput.files[0];
    if (file) {
      // Handle file upload logic here
      console.log("File selected:", file);
    }
  };

  const startIndex = paginationInfo.pageNumber * paginationInfo.pageSize + 1;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Districts setup" breadcrumbItem="Districts" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    <Button
                      color="primary"
                      className="add-btn float-end ms-2"
                      id="TooltipTop"
                      onClick={toggleFileUploadModal}
                    >
                      <i className="ri-upload-cloud-2-line align-bottom me-1"></i> Bulk upload
                    </Button>
                    <Button
                      color="primary"
                      className="add-btn float-end"
                      id="TooltipTop"
                      onClick={toggleModal}
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add
                    </Button>
                  </h4>
                </CardHeader>

                <CardBody>
                  <div>
                    <Row className="g-4 mb-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="email">
                              #
                            </th>
                            <th className="sort" data-sort="email">
                              Region
                            </th>
                            <th className="sort" data-sort="email">
                              District
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {districts.length > 0 ? (
                            districts.map((district, index) => (
                              <tr key={district.id}>
                                <td>{startIndex + index}</td>
                                <td>{district.region.name}</td>
                                <td>{district.name}</td>
                                <td>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic example"
                                  >
                                    <button
                                      id="TooltipTop"
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        openModalForEdit(district.id)
                                      }
                                    >
                                      <i className="ri-edit-box-line"></i>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        confirmDelete(district.id, loadDistricts)
                                      }
                                      className="btn btn-danger btn-sm"
                                    >
                                      <i className="ri-delete-bin-line"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>
                                No records found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      paginationInfo={paginationInfo}
                      handleNextPage={handleNextPage}
                      handlePreviousPage={handlePreviousPage}
                      onPageChange={(pageNumber, pageSize) => loadDistricts(pageNumber, pageSize)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ConfirmDialog />

      <Modal isOpen={modalOpen} toggle={toggleModal} backdrop={"static"}>
        <ModalHeader toggle={toggleModal}>
          {editingDistrictId ? "Edit district" : "Add district"}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="region">Region</Label>
              <Select
                name="region"
                id="region"
                placeholder="Select a Region"
                value={selectedRegion}
                onChange={handleSelectChange}
                options={regions}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: errors.region ? '#dc3545' : base.borderColor,
                    '&:hover': {
                      borderColor: errors.region ? '#dc3545' : base['&:hover'].borderColor,
                    },
                  }),
                }}
              />
              {errors.region && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {errors.region}
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="districtName">Name</Label>
              <Input
                type="text"
                name="districtName"
                id="districtName"
                placeholder="Enter Name"
                value={districtName}
                onChange={(e) => setDistrictName(e.target.value)}
                invalid={!!errors.districtName}
              />
              {errors.districtName && (
                <div className="invalid-feedback">{errors.districtName}</div>
              )}
            </FormGroup>
            <Button type="submit" color="primary" className="float-end">
              {editingDistrictId ? "Update" : "Save"}
            </Button>
          </form>
        </ModalBody>
      </Modal>

      <Modal isOpen={fileUploadModalOpen} toggle={toggleFileUploadModal} backdrop={"static"}>
        <ModalHeader toggle={toggleFileUploadModal}>Bulk Districts Upload</ModalHeader>
        <ModalBody>
          <form onSubmit={handleFileUpload}>
            <FormGroup>
              <Label for="fileInput">Select File</Label>
              <Input
                type="file"
                id="fileInput"
                name="fileInput"
                accept=".csv,.xlsx"
                required
              />
            </FormGroup>
            <Button type="submit" color="primary" className="float-end">
              Upload
            </Button>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DistrictListTables;
