import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-4.jpg";

const UserProfile = () => {
  document.title = "User Profile | Valitan";

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    backdropFilter: 'blur(5px)'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    borderWidth: '0.4em',
    borderTopColor: 'transparent',
    borderStyle: 'solid',
    borderRadius: '50%',
    borderColor: '#9aa0a6'
  };

  useEffect(() => {
    // Retrieve and parse user details from local storage
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      try {
        const { user } = JSON.parse(authUser);
        setUser(user);
      } catch (error) {
        console.error("Error parsing authUser from localStorage:", error);
        setUser(null); // Handle error or reset userDetails
      }
    }
    setLoading(false);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Users management" breadcrumbItem="Profile" />
          {loading && (
            <div style={overlayStyle}>
              <div style={spinnerStyle} className="spinner-border">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {!loading && user && (
            <Row>
              <Col lg="4">
                <Card>
                  <CardBody>
                    <div>
                      <img
                        src={avatar}
                        alt="User Avatar"
                        className="avatar-lg rounded-circle img-thumbnail mx-auto d-block"
                        style={{ marginBottom: '16px' }}
                      />
                    </div>
                    <hr style={{
                      width: '90%',
                      margin: '20px auto',
                      border: '0',
                      height: '1px',
                      backgroundImage: 'linear-gradient(to right, rgba(0,0,0,0), #999da1, rgba(0,0,0,0))'
                    }} />
                    <div className="text-muted">
                      <table className="table table-md">
                        <tbody>
                          <tr>
                            <td><i className="ri-share-forward-line font-size-16 align-middle text-primary me-2"></i> First Name:</td>
                            <td>{user.first_name}</td>
                          </tr>
                          <tr>
                            <td><i className="ri-share-forward-line font-size-16 align-middle text-primary me-2"></i> Middle Name:</td>
                            <td>{user.middle_name}</td>
                          </tr>
                          <tr>
                            <td><i className="ri-share-forward-line font-size-16 align-middle text-primary me-2"></i> Last Name:</td>
                            <td>{user.last_name}</td>
                          </tr>
                          <tr>
                            <td><i className="ri-share-forward-line font-size-16 align-middle text-primary me-2"></i> Sex:</td>
                            <td>{user.sex}</td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-center text-white bg-primary text-uppercase">{user.role.name}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="8" className="ms-auto">
                <Card>
                  <CardBody>
                    <div className="text-muted">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                      {/* Add more user details here as needed */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
