import React, { useEffect, useState } from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Container, Row, Modal, ModalHeader, ModalBody, FormGroup, Label, Input
} from 'reactstrap';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Pagination from "../../../components/Pagination/Pagination";
import { confirmDelete, createOrUpdateRole, getRoles as fetchRoles } from "../Roles/RoleService";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { getRole } from "../../../services/ValitanApiService";
import { ConfirmDialog } from "primereact/confirmdialog";

const RoleListTables = () => {
    document.title = "Roles | Valitan";

    const [roles, setRoles] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [roleName, setRoleName] = useState('');
    const [roleCode, setRoleCode] = useState('');
    const [errors, setErrors] = useState({ roleName: '', roleCode: '' });
    const [editingRoleId, setEditingRoleId] = useState(null);
    const [paginationInfo, setPaginationInfo] = useState({
        totalElements: 0,
        totalPages: 0,
        pageNumber: 0,
        pageSize: 10,
        sort: {}
    });

    useEffect(() => {
        loadRoles(0, paginationInfo.pageSize);
    }, [paginationInfo.pageSize]);

    const loadRoles = async (page = 0, size = 10) => {
        const { roles, paginationInfo } = await fetchRoles(page, size);
        setRoles(roles);
        setPaginationInfo(paginationInfo);
    };

    const handleNextPage = () => {
        if (paginationInfo.pageNumber < paginationInfo.totalPages - 1) {
            loadRoles(paginationInfo.pageNumber + 1, paginationInfo.pageSize);
        }
    };

    const handlePreviousPage = () => {
        if (paginationInfo.pageNumber > 0) {
            loadRoles(paginationInfo.pageNumber - 1, paginationInfo.pageSize);
        }
    };

    const openModalForEdit = async (id) => {
        setEditingRoleId(id);
        try {
            const response = await getRole(id);
            if (response) {
                setRoleName(response.data.name);
                setRoleCode(response.data.code);
                setModalOpen(true);
            } else {
                console.error("Role data is undefined");
            }
        } catch (error) {
            console.error("Error fetching role data:", error);
        }
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        if (!modalOpen) {
            setRoleName('');
            setRoleCode('');
            setErrors({ roleName: '', roleCode: '' });
            setEditingRoleId(null);
        }
    };

    const startIndex = paginationInfo.pageNumber * paginationInfo.pageSize + 1;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Roles management" breadcrumbItem="Roles" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">
                                        <Button color="primary" className="add-btn float-end" id="TooltipTop"
                                            onClick={toggleModal}>
                                            <i className="ri-add-line align-bottom me-1"></i> Add
                                        </Button>
                                    </h4>
                                </CardHeader>

                                <CardBody>
                                    <div>
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search"
                                                            placeholder="Search..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="sort" data-sort="email">#</th>
                                                        <th className="sort" data-sort="email">Name</th>
                                                        <th className="sort" data-sort="phone">Code</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {roles.length > 0 ? (
                                                        roles.map((role, index) => (
                                                            <tr key={role.id}>
                                                                <td>{startIndex + index}</td>
                                                                <td>{role.name}</td>
                                                                <td>{role.code}</td>
                                                                <td>
                                                                    <div className="btn-group" role="group"
                                                                        aria-label="Basic example">
                                                                        <button
                                                                            id="TooltipTop"
                                                                            type="button"
                                                                            className="btn btn-primary btn-sm"
                                                                            onClick={() => openModalForEdit(role.id)}
                                                                        >
                                                                            <i className="ri-edit-box-line"></i>
                                                                        </button>
                                                                        <button type="button"
                                                                            onClick={() => confirmDelete(role.id, loadRoles)}
                                                                            className="btn btn-danger btn-sm">
                                                                            <i className="ri-delete-bin-line"></i>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4" style={{ textAlign: 'center' }}>No records
                                                                found
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <Pagination
                                            paginationInfo={paginationInfo}
                                            handleNextPage={handleNextPage}
                                            handlePreviousPage={handlePreviousPage}
                                            onPageChange={(pageNumber, pageSize) => loadRoles(pageNumber, pageSize)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* ConfirmDialog */}
            <ConfirmDialog />
            <Modal isOpen={modalOpen} toggle={toggleModal} backdrop={"static"}>
                <ModalHeader toggle={toggleModal}>
                    {editingRoleId ? 'Edit Role' : 'Add Role'}
                </ModalHeader>
                <ModalBody>
                    <form
                        onSubmit={(evt) => createOrUpdateRole(evt, roleName, roleCode, editingRoleId, loadRoles, toggleModal, setErrors)}>
                        <FormGroup>
                            <Label for="roleName">Name</Label>
                            <Input
                                type="text"
                                name="roleName"
                                id="roleName"
                                placeholder="Enter Name"
                                value={roleName}
                                onChange={(e) => setRoleName(e.target.value)}
                                invalid={!!errors.roleName}
                            />
                            {errors.roleName && (
                                <div className="invalid-feedback">
                                    {errors.roleName}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="roleCode">Code</Label>
                            <Input
                                type="text"
                                name="roleCode"
                                id="roleCode"
                                placeholder="Enter Code"
                                value={roleCode}
                                onChange={(e) => setRoleCode(e.target.value)}
                                invalid={!!errors.roleCode}
                            />
                            {errors.roleCode && (
                                <div className="invalid-feedback">
                                    {errors.roleCode}
                                </div>
                            )}
                        </FormGroup>
                        <Button type="submit" color="primary" className="float-end">
                            {editingRoleId ? 'Update' : 'Save'}
                        </Button>
                    </form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default RoleListTables;
