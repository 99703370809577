import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/auth/login/actions";
import { Navigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const Logout = ({ navigate }) => {
  const dispatch = useDispatch();
  const isUserLogout = useSelector((state) => state.login.isUserLogout); // Access the correct part of state

  useEffect(() => {
    // Dispatch logout action with navigation
    dispatch(logoutUser({ navigate }));
  }, [dispatch, navigate]);

  if (isUserLogout) {
    enqueueSnackbar("You have successfully logged out", {variant: "success"})
    return <Navigate to="/login" />;
  }

  return null;
};

export default Logout;
