import { confirmDialog } from "primereact/confirmdialog";
import { createCompany, deleteCompany, updateCompany, getAllCompanies, getCompany } from "../../services/ValitanApiService";
import { enqueueSnackbar } from "notistack";

// Fetch Companies
export const getCompanies = async (page = 0, size = 10) => {
  try {
    const response = await getAllCompanies(page, size);
    if (response.data.status === "success") {
      const field = response.data.data;
      console.log("Companies fetched:", field);
      return {
        companies: field.content,
        paginationInfo: {
          totalElements: field.totalElements,
          totalPages: field.totalPages,
          pageNumber: field.number,
          pageSize: field.size,
          sort: field.sort,
        },
      };
    } else {
      enqueueSnackbar(response.data.message || "Failed to fetch companies.", { variant: "error" });
      return { companies: [], paginationInfo: {} };
    }
  } catch (error) {
    enqueueSnackbar("Error fetching companies. Please try again.", { variant: "error" });
    return { companies: [], paginationInfo: {} };
  }
};

// Delete Company
export const deleteACompany = async (id, loadCompanies) => {
  try {
    const response = await deleteCompany(id);
    if (response.data.status === "success") {
      enqueueSnackbar(response.data.message, { variant: "success" });
      await loadCompanies();
    } else {
      enqueueSnackbar(response.data.message || "Failed to delete company.", { variant: "error" });
    }
  } catch (error) {
    enqueueSnackbar("Failed to delete company. Please try again.", { variant: "error" });
    console.error(error);
  }
};

export const confirmDelete = (id, loadCompanies) => {
  confirmDialog({
    message: "Are you sure you want to delete this company?",
    header: "Confirmation",
    icon: "pi pi-question-circle",
    accept: () => deleteACompany(id, loadCompanies),
    reject: () => { },
  });
};

// Fetch a single company's details
export const viewCompanyDetails = async (
  companyId,
  setLoading,
  setCompanyName,
  setCompanyTin,
  setCompanyAddress,
  setCompanyMobileNumber,
  setCompanyEmail,
  setCompanyLogo,
  setCompanyCreatedAt,
  setModalOpen
) => {
  setLoading(true);

  try {
    const response = await getCompany(companyId);

    if (response.data.status === "success" && response.data) {
      const company = response.data.data;
      setCompanyName(company.name);
      setCompanyTin(company.tin);
      setCompanyAddress(company.address);
      setCompanyMobileNumber(company.mobileNumber);
      setCompanyEmail(company.email);
      setCompanyLogo(company.logo);
      setCompanyCreatedAt(company.createdAt);
      setModalOpen(true);  // Open the modal once the data is set
    } else {
      enqueueSnackbar(response.message || "Company data is undefined", { variant: "error" });
    }
  } catch (error) {
    enqueueSnackbar("Error fetching company details. Please try again.", { variant: "error" });
  } finally {
    setLoading(false); // Stop loading indicator after fetching data
  }
};

// Validation on create or update
export const validateCompForm = (companyName, companyTin, companyMobileNumber, setErrors) => {
  let valid = true;
  const errorsCopy = { companyName: "", companyTin: "", companyMobileNumber: "" };

  if (companyName.trim()) {
    errorsCopy.companyName = "";
  } else {
    errorsCopy.companyName = "Name is required";
    valid = false;
  }
  if (companyTin.trim()) {
    errorsCopy.companyTin = "";
  } else {
    errorsCopy.companyTin = "TIN is required";
    valid = false;
  }
  if (companyMobileNumber.trim()) {
    errorsCopy.companyMobileNumber = "";
  } else {
    errorsCopy.companyMobileNumber = "Mobile number is required";
    valid = false;
  }

  setErrors(errorsCopy);
  return valid;
};

// Create or update company
export const createOrUpdateCompany = async (event, companyName, companyTin, companyMobileNumber, companyAddress, companyEmail, companyLogo, editingCompanyId, loadCompanies, toggleModal, setErrors) => {
  event.preventDefault();

  if (!validateCompForm(companyName, companyTin, companyMobileNumber, setErrors)) {
    return;
  }

  const companyData = { name: companyName, tin: companyTin, address: companyAddress, mobileNumber: companyMobileNumber, email: companyEmail, logo: companyLogo };

  try {
    const response = editingCompanyId ? await updateCompany(editingCompanyId, companyData) : await createCompany(companyData);

    if (response.data.status === "success") {
      enqueueSnackbar(response.data.message, { variant: "success" });
      toggleModal();
      await loadCompanies();
    } else {
      enqueueSnackbar(response.data.message || "Failed to save company data.", { variant: "error" });
    }
  } catch (error) {
    enqueueSnackbar("Error saving company data. Please try again.", { variant: "error" });
  }
};
